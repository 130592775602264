import "./ResetPassword.css";

import React, { useState } from "react";
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  Button,
  FormHelperText,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  function validateCodeForm() {
    return email.length > 0;
  }

  function validateResetForm() {
    return (
      code.length > 0 && password.length > 0 && password === confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
    } catch (e) {
      console.log(e.message);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setConfirmed(true);
    } catch (e) {
      console.log(e.message);
      setIsConfirming(false);
    }
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="24"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Forgot Password
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Don&apos;t have an account?</Text>{" "}
          <Link
            as="a"
            href="/r/freetrial"
            color={mode("blue.600", "blue.200")}
            display={{
              base: "block",
              md: "inline-block",
            }}
          >
            Start a free trial.
          </Link>
        </Text>
        <Card>
          {!codeSent ? (
            <chakra.form onSubmit={handleSendCodeClick}>
              <Stack spacing="6">
                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormControl>
                <Button
                  isDisabled={!validateCodeForm()}
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  isLoading={isSendingCode}
                >
                  Send Confirmation
                </Button>
              </Stack>
            </chakra.form>
          ) : !confirmed ? (
            <chakra.form onSubmit={handleConfirmClick}>
              <Stack spacing="6">
                <FormControl id="confirmationCode">
                  <FormLabel>Confirmation Code</FormLabel>
                  <Input
                    type="tel"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                  />
                  <FormHelperText>
                    Check {email} for your confirmation code
                  </FormHelperText>
                </FormControl>
                <FormControl id="confirmPassword">
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl id="confirmPassword">
                  <FormLabel>Confirm Password</FormLabel>
                  <Input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </FormControl>
                <Button
                  isDisabled={!validateResetForm()}
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  isLoading={isConfirming}
                >
                  Reset Password
                </Button>
              </Stack>
            </chakra.form>
          ) : (
            <Text>
              Your password has been reset.{" "}
              <Link
                as="a"
                href="/login"
                color={mode("blue.600", "blue.200")}
                display={{
                  base: "block",
                  md: "inline-block",
                }}
              >
                Click here to login.
              </Link>
            </Text>
          )}
        </Card>
      </Box>
    </Box>
  );
}
