import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  VisuallyHidden,
  Button,
  Flex,
  SimpleGrid,
} from '@chakra-ui/react';
import { API } from 'aws-amplify';

import { onError } from '../libs/errorLib';
import axios from 'axios';

const Card = (props) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    py="8"
    px={{
      base: '4',
      md: '10',
    }}
    shadow="base"
    rounded={{
      sm: 'lg',
    }}
    {...props}
  />
);

export default function EditAccount(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadAccount() {
      return API.get(
        'accounts',
        `/accounts/${props.match.params.id}`
      );
    }

    async function onLoad() {
      try {
        const account = await loadAccount();

        setEmail(account.email);
        setPassword('');
        setFirstName(account.firstName);
        setLastName(account.lastName);
        setLinkedinUrl(account.linkedinUrl);
        setIsLoading(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function saveAccount(account) {
    return API.put('accounts', `/accounts/${props.match.params.id}`, {
      body: account,
    });
  }

  const pause = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveAccount({
        email,
        password,
        firstName,
        lastName,
        linkedinUrl,
        authenticationCode: password,
      });
      const account = await loadAccount();
      verifyAccount(account);
      await pause(30000);
      props.history.push(`/accounts/${props.match.params.id}`);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function loadAccount() {
    return API.get('accounts', `/accounts/${props.match.params.id}`);
  }

  async function verifyAccount(account) {
    return axios
      .post('https://legacy.api.conversify.ai/connectAccount', null, {
        params: {
          userId: account.userId,
          accountId: account.accountId,
        },
      })
      .catch((error) => {
        console.log(error);
        props.history.push(`/accounts/${account.accountId}`);
      });
  }

  return (
    <SimpleGrid columns={1} gap={2}>
      <Box
        py="24"
        px={{
          base: '4',
          lg: '8',
        }}
      >
        <Box maxW="md" mx="auto">
          <Heading
            textAlign="center"
            size="xl"
            fontWeight="extrabold"
          >
            Update Account Access
          </Heading>
          <Text
            mt="4"
            mb="8"
            align="center"
            maxW="md"
            fontWeight="medium"
          >
            <Text as="span">
              Update your secret key and LinkedIn password.
            </Text>{' '}
          </Text>
          <Card>
            <chakra.form onSubmit={handleSubmit}>
              <Stack spacing="6">
                <Text></Text>
                <Stack spacing="6">
                  <VisuallyHidden>
                    <FormControl id="email">
                      <FormLabel>Email</FormLabel>
                      <Input
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                  </VisuallyHidden>
                  <FormControl id="password">
                    <Flex align="baseline" justify="space-between">
                      <FormLabel mb={1}>LinkedIn Password</FormLabel>
                      <Link
                        as="a"
                        href="https://www.linkedin.com/psettings/change-password"
                        fontWeight="semibold"
                        fontSize="sm"
                        color={mode('blue.600', 'blue.200')}
                        target="_blank"
                      >
                        Reset LinkedIn Password
                      </Link>
                    </Flex>
                    <Input
                      value={password}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                  <VisuallyHidden>
                    <FormControl id="firstName">
                      <FormLabel>First Name</FormLabel>
                      <Input
                        value={firstName}
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FormControl>
                  </VisuallyHidden>
                  <VisuallyHidden>
                    <FormControl id="lastName">
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        value={lastName}
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </FormControl>
                  </VisuallyHidden>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    size="lg"
                    fontSize="md"
                    isLoading={isLoading}
                  >
                    Update
                  </Button>
                </Stack>
              </Stack>
            </chakra.form>
          </Card>
        </Box>
      </Box>
    </SimpleGrid>
  );
}
