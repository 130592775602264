import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {
  Box,
  Flex,
  HStack,
  Img,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
  FormControl,
  FormLabel,
  Input,
  Link,
  useToast,
} from "@chakra-ui/react";
import { ImQuotesLeft } from "react-icons/im";
import ReactPixel from "react-facebook-pixel";

function Testimonial(props) {
  console.log(props);
  return (
    <Flex as="blockquote" flex="1">
      <Box marginEnd="4" fontSize="32px" color="gray.300">
        <ImQuotesLeft />
      </Box>
      <Box>
        <Text fontSize="2xl" mt="4">
          {props.props.match.params.id === "close"
            ? "Conversify lets you enrich Sales Nav Lead Lists with verified emails and phone numbers from Apollo (we'll also automate your outreach on LinkedIn). "
            : "Try Conversify for free and if you'd like, I'll personally help get you started. We'll let you scrape contact data from your LinkedIn account and enrich those contacts with verified emails and phone numbers. Get your first set of leads for free."}
          {props.props.match.params.id === "close" && (
            <b>
              Close customers will receive a 50% discount off their subscription
              for the first year.
            </b>
          )}
        </Text>
        <HStack spacing="4" mt="8">
          <Img
            w="12"
            h="12"
            alt="David Hurley"
            rounded="full"
            objectFit="cover"
            src="/profilepic.jpeg"
          />
          <Box>
            <Text as="cite" fontStyle="normal" fontWeight="medium">
              David Hurley
            </Text>
            <br />
            <Text as="a" target="_blank">
              <b>Founder of Conversify</b>
            </Text>
            <br />
            {props.props.match.params.id === "close" && (
              <Text
                as="a"
                target="_blank"
                href="https://drift.me/davidhurley/meeting"
                color="blue.500"
              >
                If you're a Close customer, I'd like to meet with you.
              </Text>
            )}
          </Box>
        </HStack>
      </Box>
    </Flex>
  );
}

export default function SignupPage(props) {
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const toast = useToast();

  useEffect(() => {
    ReactPixel.init("468789504736376");
  }, []);

  function validateForm() {
    return (
      email.length > 5 &&
      password.length > 5 &&
      firstName.length > 1 &&
      lastName.length > 1
    );
  }

  function validateConfirmationForm() {
    return confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        attributes: {
          family_name: lastName,
          given_name: firstName,
        },
        username: email,
        password: password,
      });
      setIsLoading(false);
      setNewUser(newUser);
      toast({
        title: "Thanks!",
        description: `Check ${email} for a 6-digit confirmation code.`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "There was an error creating your account.",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  function createProfile() {
    return API.post("profiles", "/profiles", {
      body: {
        email,
        firstName,
        lastName,
        paid: false,
        refCode: props.match.params.id || "index",
      },
    });
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);
      const thisProfile = await createProfile();

      if (thisProfile) {
        ReactPixel.track("Lead");
        props.userHasAuthenticated(true);
        props.history.push("/dashboard");
        toast({
          title: "Welcome!",
          description: "Get started by connecting your LinkedIn account.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } else {
        await createProfile();
        ReactPixel.track("Lead");
        props.userHasAuthenticated(true);
        props.history.push("/dashboard");
        toast({
          title: "Welcome!",
          description: "Get started by connecting your LinkedIn account.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: "There was an error creating your account.",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  return (
    <Box
      bg={{
        md: mode("gray.100", "inherit"),
      }}
      minH={"100vh"}
      pt="16"
    >
      <Box
        maxW="6xl"
        mx="auto"
        py={{
          base: "10",
          md: "15",
        }}
        px={{
          base: "4",
          md: "10",
        }}
        scrollBehavior={"none"}
      >
        <SimpleGrid
          columns={{
            base: 1,
            lg: 2,
          }}
          spacing="14"
        >
          <Box w="full" maxW="xl" mx="auto">
            <Box
              bg={{
                md: mode("white", "gray.700"),
              }}
              rounded={{
                md: "2xl",
              }}
              p={{
                base: "4",
                md: "12",
              }}
              borderWidth={{
                md: "1px",
              }}
              borderColor={mode("gray.200", "transparent")}
              shadow={{
                md: "lg",
              }}
            >
              <Box
                mb="8"
                textAlign={{
                  base: "center",
                  md: "start",
                }}
              >
                <Heading size="lg" mb="2" fontWeight="extrabold">
                  Welcome to Conversify
                </Heading>
                <Text
                  fontSize="lg"
                  color={mode("gray.600", "gray.400")}
                  fontWeight="medium"
                >
                  Free trial, no credit card required
                </Text>
              </Box>
              {newUser === null ? (
                <form onSubmit={handleSubmit}>
                  <Stack spacing="4">
                    <HStack>
                      <FormControl id="firstName">
                        <FormLabel mb={1}>First name</FormLabel>
                        <Input
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </FormControl>
                      <FormControl id="lastName">
                        <FormLabel mb={1}>Last name</FormLabel>
                        <Input
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </FormControl>
                    </HStack>

                    <FormControl id="email">
                      <FormLabel mb={1}>Email</FormLabel>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mb={1}>Password</FormLabel>
                      <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      isLoading={isLoading}
                      type="submit"
                      colorScheme="blue"
                      size="lg"
                      fontSize="md"
                      isDisabled={!validateForm()}
                    >
                      Create my account
                    </Button>
                  </Stack>
                </form>
              ) : (
                <form onSubmit={handleConfirmationSubmit}>
                  <Stack spacing="4">
                    <FormControl id="email">
                      <FormLabel mb={1}>Confirmation Code</FormLabel>
                      <Input
                        type="tel"
                        onChange={(e) => setConfirmationCode(e.target.value)}
                        value={confirmationCode}
                      />
                    </FormControl>
                    <Button
                      isDisabled={!validateConfirmationForm()}
                      type="submit"
                      colorScheme="blue"
                      size="lg"
                      fontSize="md"
                      isLoading={isLoading}
                    >
                      Verify
                    </Button>
                  </Stack>
                </form>
              )}
            </Box>
            <Text mt="8" align="center" fontWeight="medium">
              Already have an account?{" "}
              <Link
                as="a"
                href="/login"
                color={mode("blue.600", "blue.200")}
                display={{
                  base: "block",
                  md: "inline-block",
                }}
              >
                Log in to Conversify
              </Link>
            </Text>
          </Box>

          <Flex
            direction="column"
            py="24"
            display={{
              base: "none",
              lg: "flex",
            }}
          >
            <Testimonial props={props} />
          </Flex>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
