import "./EditContact.css";

import {
  FormControl,
  FormLabel,
  FormHelperText,
  Button,
  HStack,
  Textarea,
  chakra,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";

export default function EditContact({ contactId }) {
  const [connectionRequestText, setConnectionRequestText] = useState("");
  const [firstFollowUpText, setFirstFollowUpText] = useState("");
  const [secondFollowUpText, setSecondFollowUpText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const toast = useToast();

  useEffect(() => {
    function loadContact() {
      return API.get("contacts", `/contacts/${contactId}`);
    }

    async function onLoad() {
      try {
        const contact = await loadContact();
        setConnectionRequestText(contact.connectionRequestText);
        setFirstFollowUpText(contact.firstFollowUpText);
        setSecondFollowUpText(contact.secondFollowUpText);
        setLinkedinUrl(contact.linkedinUrl);
        setFirstName(contact.firstName);
        setLastName(contact.lastName);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [contactId]);

  function saveContact(contact) {
    return API.put("contacts", `/contacts/${contactId}`, {
      body: contact,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveContact({
        connectionRequestText,
        firstFollowUpText,
        secondFollowUpText,
        linkedinUrl,
        firstName,
        lastName,
      });
      setIsLoading(false);
      toast({
        title: `${firstName} ${lastName} has been updated!`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <chakra.form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Connection Request</FormLabel>
          <Textarea
            value={connectionRequestText}
            onChange={(e) => setConnectionRequestText(e.target.value)}
          />
          <FormHelperText>
            Connection request must be 300 characters or less.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>First Follow-up</FormLabel>
          <Textarea
            value={firstFollowUpText}
            onChange={(e) => setFirstFollowUpText(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Second Follow-up</FormLabel>
          <Textarea
            value={secondFollowUpText}
            onChange={(e) => setSecondFollowUpText(e.target.value)}
          />
        </FormControl>
        <HStack mt={4}>
          <Button type="submit" isLoading={isLoading} colorScheme={"blue"}>
            Save messages
          </Button>
        </HStack>
      </Stack>
    </chakra.form>
  );
}
