import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { onError } from "../libs/errorLib";
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  Button,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function EditAccount(props) {
  const [codeSent, setCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const toast = useToast();

  function validateEmailForm() {
    return email.length > 0;
  }

  function validateConfirmForm() {
    return code.length > 0;
  }

  async function handleUpdateClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email });
      setCodeSent(true);
    } catch (error) {
      onError(error);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
      setIsConfirming(false);
      toast({
        title: "Your email is updated",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      props.handleLogout();
    } catch (error) {
      onError(error);
      toast({
        title: "There was an error updating your email.",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsConfirming(false);
    }
  }
  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="24"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Change your email
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Not in the right spot?</Text>{" "}
          <Link
            as="a"
            href="/dashboard"
            color={mode("blue.600", "blue.200")}
            display={{
              base: "block",
              md: "inline-block",
            }}
          >
            Go back to dashboard.
          </Link>
        </Text>
        <Card>
          {!codeSent ? (
            <chakra.form onSubmit={handleUpdateClick}>
              <Stack spacing="6">
                <FormControl id="email">
                  <FormLabel>Email address</FormLabel>
                  <Input
                    autoFocus
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <Button
                  block
                  type="submit"
                  isLoading={isSendingCode}
                  disabled={!validateEmailForm()}
                  colorScheme="blue"
                >
                  Change email
                </Button>
              </Stack>
            </chakra.form>
          ) : (
            <chakra.form onSubmit={handleConfirmClick}>
              <Stack spacing="6">
                <FormControl id="email">
                  <FormLabel>Confirmation Code</FormLabel>
                  <Input
                    autoFocus
                    type="tel"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <FormHelperText>
                    Check {email} for the confirmation code.
                  </FormHelperText>
                </FormControl>

                <Button
                  block
                  type="submit"
                  bsSize="large"
                  isLoading={isConfirming}
                  disabled={!validateConfirmForm()}
                  colorScheme="blue"
                >
                  Confirm
                </Button>
              </Stack>
            </chakra.form>
          )}
        </Card>
      </Box>
    </Box>
  );
}
