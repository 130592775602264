import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  HStack,
  Text,
  Stack,
} from "@chakra-ui/react";
import { RiSearch2Line } from "react-icons/ri";
import { useState } from "react";
import { API } from "aws-amplify";

const EnrichContactsModal = ({ contacts, setContacts }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState("");

  function saveContact(contact) {
    return API.put("contacts", `/contacts/${contact.contactId}`, {
      body: contact,
    });
  }

  function enrich(contactToEnrich) {
    return API.post("contacts", "/enrichcontacts", {
      body: contactToEnrich,
    });
  }

  async function enrichContacts() {
    setIsLoading(true);
    let minutes = parseInt((contacts.length * 2) / 60);
    if (minutes === 0) minutes = 1;
    toast({
      title: `Finding emails for ${contacts.length} ${
        contacts.length === 1 ? "contact" : "contacts"
      }.`,
      description: `Hold tight. It'll take around ${minutes} ${
        minutes === 1 ? "minute" : "minutes"
      } to search all contacts.`,
      status: "success",
      duration: 8000,
      isClosable: true,
    });

    const enrichedContacts = [];

    for (let index in contacts) {
      const contactToEnrich = contacts[index];

      try {
        const res = await enrich({ contactToEnrich });
        const contact = res.contact;
        enrichedContacts.push(contact);
        await saveContact(contact);
      } catch (e) {
        console.log(e);
      }
    }
    setContacts(enrichedContacts);
    onClose();
    setIsLoading(false);
  }

  return (
    <>
      <Button
        iconSpacing="1"
        borderColor="gray.300"
        background="#fff"
        leftIcon={<RiSearch2Line fontSize="1em" />}
        disabled={contacts.length === 0}
        onClick={onOpen}
      >
        Enrich
      </Button>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pb={4}>
          <ModalHeader fontWeight="bold">Enrich Contacts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text>
                We'll enrich each contact with their email and phone number.{" "}
                <b>Do not close this as we are enriching your contacts.</b>{" "}
                Message our support chat for bigger lists.
              </Text>
              <HStack>
                <Button
                  isLoading={isLoading}
                  onClick={() => enrichContacts()}
                  colorScheme="blue"
                  size="sm"
                >
                  Enrich contacts
                </Button>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnrichContactsModal;
