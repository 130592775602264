import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { onError } from "../libs/errorLib";
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  Button,
  useToast,
} from "@chakra-ui/react";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function EditAccount(props) {
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChanging, setIsChanging] = useState(false);
  const toast = useToast();

  function validateForm() {
    return (
      oldPassword.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  async function handleChangeClick(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, password);
      toast({
        title: "Your password is updated",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      onError(error);
      toast({
        title: "There was an error updating your password",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsChanging(false);
    }
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="24"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Update your password
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Not in the right spot?</Text>{" "}
          <Link
            as="a"
            href="/dashboard"
            color={mode("blue.600", "blue.200")}
            display={{
              base: "block",
              md: "inline-block",
            }}
          >
            Go back to dashboard.
          </Link>
        </Text>
        <Card>
          <chakra.form onSubmit={handleChangeClick}>
            <Stack spacing="6">
              <FormControl>
                <FormLabel>Old Password</FormLabel>
                <Input
                  type="password"
                  onChange={(e) => setOldPassword(e.target.value)}
                  value={oldPassword}
                />
              </FormControl>
              <FormControl>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Change Password</FormLabel>
                <Input
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
              </FormControl>
              <Button
                block
                type="submit"
                bsSize="large"
                disabled={!validateForm()}
                isLoading={isChanging}
                colorScheme={"blue"}
              >
                Update password
              </Button>
            </Stack>
          </chakra.form>
        </Card>
      </Box>
    </Box>
  );
}
