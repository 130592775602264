import { Box, Flex, HStack, useMenuButton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HiSelector } from 'react-icons/hi';
import { API, Auth } from 'aws-amplify';
import { Skeleton } from '@chakra-ui/react';

const AccountSettingsButton = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get('profiles', `/myprofile/${userId}`);
    }

    async function onLoad() {
      setIsLoading(true);
      const user = await Auth.currentUserInfo();
      const profile = await loadProfile(user['id']);
      setProfile(profile);

      setIsLoading(false);
    }

    onLoad();
  }, []);
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      px="3"
      py="2"
      mb={2}
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{
        bg: 'gray.200',
      }}
      _focus={{
        shadow: 'outline',
      }}
    >
      <HStack flex="1" spacing="3">
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold">
            {isLoading ? (
              <Skeleton height="15px" w="full" />
            ) : (
              `${profile.firstName} ${profile.lastName}`
            )}
          </Box>
          <Box fontSize="xs" color="gray.500">
            {isLoading ? (
              <Skeleton height="15px" w="full" />
            ) : profile.email.length > 20 ? (
              profile.email.slice(0, 20)
            ) : (
              profile.email
            )}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  );
};

export default AccountSettingsButton;
