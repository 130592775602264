import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { FiActivity } from "react-icons/fi";
import Activity from "./Activity";

const ViewCampaignModal = ({ campaignId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} size="xs">
        <FiActivity />
      </Button>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="bold">View Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Box px={6} pb={6} pt={4} mx="auto" maxW="2xl" minH="400px">
              <Activity campaignId={campaignId} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewCampaignModal;
