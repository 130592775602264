import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { API } from "aws-amplify";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function EditTemplate(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [connectionRequestText, setConnectionRequestText] = useState("");
  const [firstFollowUpText, setFirstFollowUpText] = useState("");
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [hasFirstFollowup, setHasFirstFollowup] = useState(false);
  const [secondFollowUpText, setSecondFollowUpText] = useState("");
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [thirdFollowUpText, setThirdFollowUpText] = useState("");
  const [thirdFollowUpDays, setThirdFollowUpDays] = useState(2);
  const [hasThirdFollowup, setHasThirdFollowup] = useState(false);
  const [fourthFollowUpText, setFourthFollowUpText] = useState("");
  const [fourthFollowUpDays, setFourthFollowUpDays] = useState(2);
  const [hasFourthFollowup, setHasFourthFollowup] = useState(false);
  const [fifthFollowUpText, setFifthFollowUpText] = useState("");
  const [fifthFollowUpDays, setFifthFollowUpDays] = useState(2);
  const [hasFifthFollowup, setHasFifthFollowup] = useState(false);
  const [sixthFollowUpText, setSixthFollowUpText] = useState("");
  const [sixthFollowUpDays, setSixthFollowUpDays] = useState(2);
  const [hasSixthFollowup, setHasSixthFollowup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadTemplate() {
      return API.get("templates", `/templates/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate();
        setTitle(template.title);
        setDescription(template.description);
        setConnectionRequestText(template.connectionRequestText);
        "firstFollowUpText" in template
          ? setFirstFollowUpText(template.firstFollowUpText)
          : setFirstFollowUpText("");
        "firstFollowUpDays" in template
          ? setFirstFollowUpDays(template.firstFollowUpDays)
          : setFirstFollowUpDays("");
        "hasFirstFollowup" in template
          ? setHasFirstFollowup(template.hasFirstFollowup)
          : setHasFirstFollowup(false);
        "secondFollowUpText" in template
          ? setSecondFollowUpText(template.secondFollowUpText)
          : setSecondFollowUpText("");
        "secondFollowUpDays" in template
          ? setSecondFollowUpDays(template.secondFollowUpDays)
          : setSecondFollowUpDays("");
        "hasSecondFollowup" in template
          ? setHasSecondFollowup(template.hasSecondFollowup)
          : setHasSecondFollowup(false);

        "thirdFollowUpText" in template
          ? setThirdFollowUpText(template.thirdFollowUpText)
          : setThirdFollowUpText("");
        "thirdFollowUpDays" in template
          ? setThirdFollowUpDays(template.thirdFollowUpDays)
          : setThirdFollowUpDays("");
        "hasThirdFollowup" in template
          ? setHasThirdFollowup(template.hasThirdFollowup)
          : setHasThirdFollowup(false);

        "fourthFollowUpText" in template
          ? setFourthFollowUpText(template.fourthFollowUpText)
          : setFourthFollowUpText("");
        "fourthFollowUpDays" in template
          ? setFourthFollowUpDays(template.fourthFollowUpDays)
          : setFourthFollowUpDays("");
        "hasFourthFollowup" in template
          ? setHasFourthFollowup(template.hasFourthFollowup)
          : setHasFourthFollowup(false);

        "fifthFollowUpText" in template
          ? setFifthFollowUpText(template.fifthFollowUpText)
          : setFifthFollowUpText("");
        "fifthFollowUpDays" in template
          ? setFifthFollowUpDays(template.fifthFollowUpDays)
          : setFifthFollowUpDays("");
        "hasFifthFollowup" in template
          ? setHasFifthFollowup(template.hasFifthFollowup)
          : setHasFifthFollowup(false);

        "sixthFollowUpText" in template
          ? setSixthFollowUpText(template.sixthFollowUpText)
          : setSixthFollowUpText("");
        "sixthFollowUpDays" in template
          ? setSixthFollowUpDays(template.sixthFollowUpDays)
          : setSixthFollowUpDays("");
        "hasSixthFollowup" in template
          ? setHasSixthFollowup(template.hasSixthFollowup)
          : setHasSixthFollowup(false);
        setIsLoading(false);
      } catch (e) {}
    }

    onLoad();
  }, [props.templateId, props.match.params.id]);

  function validateForm() {
    return title && title.length > 1;
  }

  function saveTemplate(template) {
    return API.put("templates", `/templates/${props.match.params.id}`, {
      body: template,
    });
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveTemplate({
        title,
        description,
        connectionRequestText,
        firstFollowUpText,
        firstFollowUpDays,
        hasFirstFollowup,
        secondFollowUpText,
        secondFollowUpDays,
        hasSecondFollowup,
        thirdFollowUpText,
        thirdFollowUpDays,
        hasThirdFollowup,
        fourthFollowUpText,
        fourthFollowUpDays,
        hasFourthFollowup,
        fifthFollowUpText,
        fifthFollowUpDays,
        hasFifthFollowup,
        sixthFollowUpText,
        sixthFollowUpDays,
        hasSixthFollowup,
      });
      props.history.push("/templates");
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="24"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Edit {title}
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Wrong spot?</Text>{" "}
          <Link
            as="a"
            href="/dashboard"
            color={mode("blue.600", "blue.200")}
            display={{
              base: "block",
              md: "inline-block",
            }}
          >
            Head back to the dashboard.
          </Link>
        </Text>
        <Card>
          <chakra.form onSubmit={handleSubmit}>
            <Stack spacing="6">
              <FormControl id="title">
                <FormLabel>Title</FormLabel>
                <Input
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </FormControl>
              {connectionRequestText && connectionRequestText.length > 290 ? (
                <div className="warning-label">
                  <p className="text-center" style={{ position: "inherit" }}>
                    Your connection request template is too long
                  </p>
                </div>
              ) : null}

              <FormControl id="connectionRequest">
                <FormLabel>Connection Request</FormLabel>
                <Textarea
                  value={connectionRequestText}
                  componentClass="textarea"
                  onChange={(e) => setConnectionRequestText(e.target.value)}
                />
              </FormControl>

              <FormControl id="hasFirstFollowup">
                <FormLabel>Send Follow Up?</FormLabel>
                <input
                  checked={hasFirstFollowup}
                  onChange={handleFirstFollowup}
                  type="checkbox"
                />
              </FormControl>

              {hasFirstFollowup && hasFirstFollowup ? (
                <div>
                  <FormControl id="firstFollowUpDays">
                    <FormLabel>How many days after?</FormLabel>
                    <Input
                      value={firstFollowUpDays}
                      type="number"
                      onChange={(e) => setFirstFollowUpDays(e.target.value)}
                      required
                    />
                  </FormControl>

                  <FormControl id="firstFollowUpText">
                    <FormLabel>First Follow-up</FormLabel>
                    <Textarea
                      value={firstFollowUpText}
                      onChange={(e) => setFirstFollowUpText(e.target.value)}
                    />
                  </FormControl>

                  <FormControl id="hasSecondFollowup">
                    <FormLabel>Send Another Follow Up?</FormLabel>
                    <input
                      checked={hasSecondFollowup}
                      onChange={handleSecondFollowup}
                      type="checkbox"
                    />
                  </FormControl>
                </div>
              ) : null}
              {hasSecondFollowup && hasSecondFollowup ? (
                <div>
                  <FormControl id="secondFollowUpDays">
                    <FormLabel>How many days after?</FormLabel>
                    <Input
                      value={secondFollowUpDays}
                      type="number"
                      onChange={(e) => setSecondFollowUpDays(e.target.value)}
                      required
                    />
                  </FormControl>

                  <FormControl id="secondFollowUpText">
                    <FormLabel>Second Followup Message</FormLabel>
                    <Textarea
                      value={secondFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setSecondFollowUpText(e.target.value)}
                    />
                  </FormControl>
                </div>
              ) : null}

              <Button
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </Button>
            </Stack>
          </chakra.form>
        </Card>
      </Box>
    </Box>
  );
}
