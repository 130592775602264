import React, { useState } from 'react';
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  Flex,
  Button,
  SimpleGrid,
  GridItem,
  useToast,
} from '@chakra-ui/react';
import { API } from 'aws-amplify';
import axios from 'axios';

const Card = (props) => (
  <Box
    bg={useColorModeValue('white', 'gray.700')}
    py="8"
    px={{
      base: '4',
      md: '10',
    }}
    shadow="base"
    rounded={{
      sm: 'lg',
    }}
    {...props}
  />
);

export default function NewAccount(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  function validateForm() {
    return password.length >= 6;
  }

  const pause = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const account = await createAccount({
        email,
        password,
        authenticationCode: password,
        firstName,
        linkedinUrl: 'none',
        lastName,
      });

      verifyAccount(account);
      await pause(30000);
      props.history.push(`/accounts/${account.accountId}`);
      setIsLoading(false);
      toast({
        title: 'Verifying your LinkedIn credentials',
        description: `Sit tight, it takes about a minute to verify.`,
        status: 'success',
        duration: 8000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "There's an error with your LinkedIn credentials.",
        description: e.message,
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  async function verifyAccount(account) {
    return axios.post(
      'https://legacy.api.conversify.ai/connectAccount',
      null,
      {
        params: {
          userId: account.userId,
          accountId: account.accountId,
        },
      }
    );
  }

  function createAccount(account) {
    return API.post('accounts', '/accounts', {
      body: account,
    });
  }

  return (
    <SimpleGrid columns={1} gap={2}>
      <Box
        bg={useColorModeValue('gray.50', 'inherit')}
        py="12"
        minH={'100vh'}
        px={{
          base: '4',
          lg: '8',
        }}
      >
        <Box maxW="md" mx="auto">
          <Heading
            textAlign="center"
            size="2xl"
            fontWeight="extrabold"
          >
            New Account
          </Heading>
          <Text
            mt="4"
            mb="8"
            align="center"
            maxW="md"
            fontWeight="medium"
          >
            Connect another LinkedIn account to Conversify.
          </Text>
          <Card>
            <chakra.form onSubmit={handleSubmit}>
              <Stack spacing="6">
                <SimpleGrid columns={2} spacing={2}>
                  <GridItem>
                    <FormControl id="firstName">
                      <FormLabel>First Name</FormLabel>
                      <Input
                        autoFocus
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl id="firstName">
                      <FormLabel>Last Name</FormLabel>
                      <Input
                        autoFocus
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
                <FormControl id="email">
                  <FormLabel>LinkedIn email address</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormControl>
                <FormControl id="password">
                  <Flex align="baseline" justify="space-between">
                    <FormLabel mb={1}>LinkedIn password</FormLabel>
                    <Link
                      as="a"
                      href="https://www.linkedin.com/psettings/change-password"
                      fontWeight="semibold"
                      fontSize="sm"
                      color={mode('blue.600', 'blue.200')}
                      target="_blank"
                    >
                      Reset password
                    </Link>
                  </Flex>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormControl>
                <Button
                  isDisabled={!validateForm()}
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  isLoading={isLoading}
                >
                  Save and continue
                </Button>
              </Stack>
            </chakra.form>
          </Card>
        </Box>
      </Box>
    </SimpleGrid>
  );
}
