import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  IconButton,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { FiEdit, FiAlertTriangle } from "react-icons/fi";
import EditContact from "./EditContact";

const EditContactModal = ({ contact }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack>
        <IconButton onClick={onOpen} icon={<FiEdit />} size="xs" />

        {((contact["connectionRequestText"] &&
          contact.connectionRequestText.includes("undefined")) ||
          (contact["firstFollowUpText"] &&
            contact.firstFollowUpText.includes("undefined")) ||
          (contact["secondFollowUpText"] &&
            contact.secondFollowUpText.includes("undefined"))) && (
          <HStack>
            <Tooltip
              hasArrow
              bg="red.600"
              label={`Looks like there's an error in ${contact.firstName} ${contact.lastName}'s sequence.`}
              aria-label="A tooltip"
              placement="bottom-start"
              closeOnClick={true}
            >
              <FiAlertTriangle color="red" />
            </Tooltip>
          </HStack>
        )}
      </HStack>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="bold">Edit Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Box px={6} pb={6} pt={4} mx="auto" maxW="2xl" minH="400px">
              <EditContact contactId={contact.contactId} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditContactModal;
