import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import AccountSettingsButton from './AccountSettingsButton';

const AccountSettings = ({ props }) => {
  return (
    <Menu>
      <AccountSettingsButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="4"
      >
        <MenuItem
          rounded="md"
          onClick={() => props.history.push('/subscription')}
        >
          Subscription
        </MenuItem>
        <MenuItem
          rounded="md"
          onClick={() => props.history.push('/settings/email')}
        >
          Change Email
        </MenuItem>
        <MenuItem
          rounded="md"
          onClick={() => props.history.push('/settings/password')}
        >
          Change Password
        </MenuItem>
        <MenuDivider />
        <MenuItem rounded="md" onClick={() => props.handleLogout()}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default AccountSettings;
