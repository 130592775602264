import {
  Box,
  Text,
  Stack,
  Link,
  HStack,
  Button,
  ButtonGroup,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { contactsInCamapign } from "../libs/contactsInCampaign";
import moment from "moment";
import ContactsTable from "./ContactsTable";
import { RiArrowRightUpLine, RiDeleteBin6Line } from "react-icons/ri";
import { CSVLink } from "react-csv";
import { FaLinkedin } from "react-icons/fa";
import ContactsSkeleton from "./ContactsSkeleton";
import SyncContactsModal from "./SyncContactsModal";
import EnrichContactsModal from "./EnrichContactsModal";

export default function Contacts({ accountId }) {
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectAllContacts, setSelectAllContacts] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [contact, setContact] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [unlabledContact, setUnlabledContact] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    async function onLoad() {
      const campaigns = await loadCampaigns();

      const userCampaigns = campaigns
        .filter(
          (campaign) =>
            campaign.accountId === accountId && campaign.contacts > 0
        )
        .sort((a, b) => b.createdAt - a.createdAt);

      let contacts = [];

      if (userCampaigns.length > 0) {
        contacts = await contactsInCamapign(userCampaigns[0].campaignId);
      }

      try {
        const campaigns = [];
        for (let campaignIndex in userCampaigns) {
          const value = userCampaigns[campaignIndex].campaignId;
          const label = userCampaigns[campaignIndex].title;
          const createdAt = userCampaigns[campaignIndex].createdAt;
          campaigns.push({
            label,
            value,
            createdAt,
          });
        }
        if (campaigns.length > 0) {
          const sortedCampaigns = campaigns.sort(
            (a, b) => b.createdAt - a.createdAt
          );
          setCampaigns(sortedCampaigns);
          setCampaign(sortedCampaigns[0].label);
          setContacts(contacts);
        }
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }
    onLoad();
  }, [accountId]);

  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  function selectContact(contactId) {
    if (selectedContacts.includes(contactId)) {
      setSelectedContacts(
        selectedContacts.filter(
          (char, i) => i !== selectedContacts.indexOf(contactId)
        )
      );
    } else {
      setSelectedContacts([contactId].concat(selectedContacts));
    }
  }

  function selectAll() {
    if (selectAllContacts) {
      setSelectedContacts([]);
      setSelectAllContacts(false);
    } else {
      const allContacts = [];
      for (let contactIndex in contacts) {
        allContacts.push(contacts[contactIndex].contactId);
      }
      setSelectedContacts(allContacts);
      setSelectAllContacts(true);
    }
  }

  function showConversation(contact) {
    onOpen();
    setUnlabledContact(contact);
    setContact(contact);
  }

  async function updateCampaign(campaignTitle) {
    setIsLoading(true);
    setCampaign(campaignTitle);
    const updatedCampaign = campaigns.filter(
      (camp) => camp.label.trim() === campaignTitle
    )[0];
    setSelectedContacts([]);

    let contacts = await contactsInCamapign(updatedCampaign.value);
    setContacts(contacts);
    setIsLoading(false);
    setSelectAllContacts(false);
  }

  function deleteContact(contactId) {
    return API.del("contacts", `/contacts/${contactId}`);
  }

  function updateSentiment(contact) {
    return API.put("contacts", `/updatesentiment`, {
      body: contact,
    });
  }

  async function label(conversationSentiment) {
    unlabledContact["conversationSentiment"] = conversationSentiment;

    const updatedContact = {
      campaignId: unlabledContact.campaignId,
      userId: unlabledContact.userId,
      conversationSentiment,
      templateId: unlabledContact.templateId,
      contactId: unlabledContact.contactId,
      campaignSlug: unlabledContact.campaignSlug,
      linkedinUrl: unlabledContact.linkedinUrl,
    };

    try {
      await updateSentiment(updatedContact);
      let contacts = await contactsInCamapign(unlabledContact.campaignId);
      setContacts(contacts);
    } catch (e) {
      onError(e);
    }
  }

  function cleanName(name) {
    let cleanName = name;
    if (cleanName.includes(" ")) cleanName = cleanName.split(" ")[0];
    cleanName = cleanName.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );

    return cleanName;
  }

  function cleanContacts(contacts) {
    let newContacts = [];
    let cleanCampaign = campaign;
    if (!campaign) cleanCampaign = campaigns[0].label;
    contacts.forEach((contact) => {
      newContacts.push({
        campaign: cleanCampaign,
        firstName: cleanName(contact.firstName) || "",
        lastName: cleanName(contact.lastName) || "",
        linkedinUrl: contact.linkedinUrl || "",
        headline: contact["linkedinHeadline"] || "",
        location: contact.location || "",
        company: contact["company"] || "",
        imported: moment(contact["createdAt"]).format("MMM Do") || "",
        Email: contact["email"] || "",
        Phone: contact["phone"] || "",
      });
    });
    return newContacts;
  }

  function deleteContacts() {
    for (const idx in selectedContacts) {
      const contactId = selectedContacts[idx];
      if (typeof contactId === "string") {
        deleteContact(contactId);
      }
    }
    let newContacts = contacts.filter(
      (contact) => !selectedContacts.includes(contact.contactId)
    );
    setContacts(newContacts);
    setSelectedContacts([]);
    setSelectAllContacts(false);
  }

  function copyEmail(email) {
    navigator.clipboard.writeText(email);
    toast({
      description: `${email} copied!`,
      status: "success",
      duration: 8000,
      isClosable: true,
    });
  }

  function copyPhone(number) {
    navigator.clipboard.writeText(number);
    toast({
      description: `${number} copied!`,
      status: "success",
      duration: 8000,
      isClosable: true,
    });
  }

  return (
    <Box p="4" mt={0}>
      {isLoading ? (
        <ContactsSkeleton />
      ) : (
        <div>
          {contacts.length > 0 && (
            <Stack
              spacing="4"
              direction={{ base: "column", md: "row" }}
              justify="space-between"
            >
              <HStack>
                <Select
                  minW={{ md: "100px" }}
                  rounded="base"
                  size="sm"
                  placeholder={campaign}
                  onChange={(e) => updateCampaign(e.target.value)}
                >
                  {campaigns &&
                    campaigns.map((campaign, i) => (
                      <option key={i}>{campaign.label}</option>
                    ))}
                </Select>
              </HStack>
              <ButtonGroup size="sm" variant="outline">
                <EnrichContactsModal
                  setContacts={setContacts}
                  contacts={contacts.filter((contact) =>
                    selectedContacts.includes(contact.contactId)
                  )}
                />
                <SyncContactsModal
                  contacts={contacts.filter((contact) =>
                    selectedContacts.includes(contact.contactId)
                  )}
                  setContacts={setContacts}
                  accountId={accountId}
                />
                {selectedContacts.length > 0 && (
                  <CSVLink
                    data={cleanContacts(
                      contacts.filter((contact) =>
                        selectedContacts.includes(contact.contactId)
                      )
                    )}
                    filename={`${campaign}.csv`}
                    target="_blank"
                  >
                    <Button
                      iconSpacing="1"
                      borderColor="gray.300"
                      background="#fff"
                      leftIcon={<RiArrowRightUpLine fontSize="1.25em" />}
                      disabled={selectedContacts.length === 0}
                    >
                      Download
                    </Button>
                  </CSVLink>
                )}

                <Button
                  iconSpacing="1"
                  borderColor="gray.300"
                  background="#fff"
                  leftIcon={<RiDeleteBin6Line fontSize="1em" />}
                  onClick={() => deleteContacts()}
                  disabled={selectedContacts.length === 0}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Stack>
          )}

          {contacts.length > 0 ? (
            <ContactsTable
              selectContact={selectContact}
              selectedContacts={selectedContacts}
              selectAllContacts={selectAllContacts}
              selectAll={selectAll}
              contacts={contacts}
              showConversation={showConversation}
              copyEmail={copyEmail}
              copyPhone={copyPhone}
            />
          ) : (
            <Stack spacing="3" px={2}>
              <Text maxW={"650px"}>
                You can use Conversify to import contact data from your LinkedIn
                account. If you have LinkedIn Sales Nav you can import a Lead
                List or Saved Search. After you import, we'll enrich those
                contacts with work emails and phone numbers for you.
              </Text>
            </Stack>
          )}
          {contact && (
            <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontWeight="bold">
                  <HStack>
                    <Link isExternal href={contact.linkedinUrl}>
                      <FaLinkedin color="#0072b1" />
                    </Link>
                    <Text>
                      {contact.firstName} {contact.lastName}
                    </Text>
                  </HStack>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody py={4} px={6}>
                  <Button
                    onClick={() => label("POSITIVE")}
                    colorScheme={"green"}
                    size={"xs"}
                    isDisabled={contact.conversationSentiment === "POSITIVE"}
                  >
                    Positive
                  </Button>{" "}
                  <Button
                    onClick={() => label("NEUTRAL")}
                    size={"xs"}
                    colorScheme={"yellow"}
                    isDisabled={contact.conversationSentiment === "NEUTRAL"}
                    mr={2}
                  >
                    Neutral
                  </Button>
                  <Button
                    onClick={() => label("NEGATIVE")}
                    size={"xs"}
                    colorScheme={"red"}
                    isDisabled={contact.conversationSentiment === "NEGATIVE"}
                  >
                    Negative
                  </Button>{" "}
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </div>
      )}
    </Box>
  );
}
