import "./EditCampaign.css";

import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { onError } from "../libs/errorLib";
import ViewTemplateItem from "./ViewTemplateItem";
import { contactsInCamapign } from "../libs/contactsInCampaign";

export default function EditCampaign(props) {
  const [campaign, setCampaign] = useState("");
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    function loadCampaign() {
      return API.get("campaigns", `/campaigns/${props.campaignId}`);
    }

    async function onLoad() {
      try {
        const campaign = await loadCampaign();
        const contacts = await contactsInCamapign(props.campaignId);
        setContacts(contacts);
        setCampaign(campaign);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [props.campaignId]);

  return (
    <>
      {contacts.length > 0 && (
        <ViewTemplateItem
          campaignId={campaign.campaignId}
          templateId={contacts[0].templateId}
        />
      )}
    </>
  );
}
