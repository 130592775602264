import "./Accounts.css";

import React, { useEffect } from "react";
import { onError } from "../libs/errorLib";
import accountApi from "api/account";

export default function Dashboard(props) {
  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await accountApi.getAll();

        if (accounts.length > 0) {
          props.history.push(`/accounts/${accounts[0].accountId}`);
        } else {
          props.history.push(`/onboarding`);
        }
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [props.history]);

  return <div className=""></div>;
}
