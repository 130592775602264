import API from "@aws-amplify/api";
import axios from "axios";

class AccountApi {
    create = async (account) => API.post("accounts", "/accounts", { body: account });
    
    verify = async (account) => {
        return axios.post("https://legacy.api.conversify.ai/connectAccount", null, {
            params: { userId: account.userId, accountId: account.accountId },
        });
    }
    VERIFY_ERROR_CODES = {
        INVALID_CREDENTIALS: 'credentials.invalid',
        CHALLENGE_GLOBAL: 'challenge_global_internal_error',
        MFA_MISSING: '2fa.missing',
    }

    get = (accountId) => {
        return API.get("accounts", `/accounts/${accountId}`);
    }
    
    getAll = () => {
        return API.get("accounts", `/accounts`);
    }
    
    updatePin = (accountId, pin) => {
        return API.put("accounts", `/updatepin/${accountId}`, {
            body: {
                pin,
                accountId,
            },
        });
        
    }

    delete = (accountId) => {
        return API.del("accounts", `/accounts/${accountId}`);
    }
}

const accountApi = new AccountApi();

export default accountApi;





window.accountApi = accountApi;
// window.deleteAllAccounts = () => {
//     accountApi.getAll().then(res => res.forEach(account => accountApi.delete(account.accountId)))
// }