import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";

export default function ChangeName(props) {
  const [profileId, setProfileId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadProfile(id) {
      return API.get("profiles", `/myprofile/${id}`);
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();

        try {
          const profile = await loadProfile(user["id"]);
          setProfileId(profile.profileId);
          setFirstName(profile.firstName);
          setLastName(profile.lastName);
        } catch (e) {
          onError(e);
        }

        setIsLoading(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  function saveProfile(account) {
    return API.put("accounts", `/profiles/${profileId}`, {
      body: account,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveProfile({
        firstName,
        lastName,
      });
      props.history.push(`/`);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="EditAccount">
      <br />
      <br />
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="firstName">
            <ControlLabel>First Name</ControlLabel>
            <FormControl
              value={firstName}
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup controlId="lastName">
            <ControlLabel>Last Name</ControlLabel>
            <FormControl
              value={lastName}
              type="text"
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <LoaderButton
            type="submit"
            className="other-btn"
            isLoading={isLoading}
          >
            Update Name
          </LoaderButton>
        </form>
      </div>
    </div>
  );
}
