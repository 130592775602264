import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  Button,
  Text,
} from "@chakra-ui/react";
import { FiMessageSquare } from "react-icons/fi";
import EditCampaign from "./EditCampaign";

const EditCampaignModal = ({ campaignId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} size="xs">
        <FiMessageSquare />
      </Button>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="bold">Edit Campaign</ModalHeader>
          <ModalCloseButton />

          <ModalBody p={0}>
            <Text px={6} as="i" fontSize={"sm"}>
              This will update the template you used to create this campaign.
            </Text>
            <Box px={6} pb={6} pt={4} mx="auto" maxW="2xl" minH="400px">
              <EditCampaign campaignId={campaignId} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditCampaignModal;
