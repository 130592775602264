import React, { useState } from "react";
import {
  Box,
  Text,
  useColorModeValue,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  Flex,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../../libs/hooksLib";
import { Button } from "@chakra-ui/react";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      props.userHasAuthenticated(true);
    } catch (e) {
      console.log(e.message);
      setIsLoading(false);
    }
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="24"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          Sign in to your account
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Don&apos;t have an account?</Text>{" "}
          <Link
            as="a"
            href="/r/freetrial"
            color={mode("blue.600", "blue.200")}
            display={{
              base: "block",
              md: "inline-block",
            }}
          >
            Create an account.
          </Link>
        </Text>
        <Card>
          <chakra.form onSubmit={handleSubmit}>
            <Stack spacing="6">
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  autoFocus
                  type="email"
                  value={fields.email}
                  onChange={handleFieldChange}
                  required
                />
              </FormControl>
              <FormControl id="password">
                <Flex align="baseline" justify="space-between">
                  <FormLabel mb={1}>Password</FormLabel>
                  <Link
                    as="a"
                    href="/login/reset"
                    fontWeight="semibold"
                    fontSize="sm"
                    color={mode("blue.600", "blue.200")}
                  >
                    Forgot Password?
                  </Link>
                </Flex>
                <Input
                  type="password"
                  value={fields.password}
                  onChange={handleFieldChange}
                  required
                />
              </FormControl>
              <Button
                isDisabled={!validateForm()}
                type="submit"
                colorScheme="blue"
                size="lg"
                fontSize="md"
                isLoading={isLoading}
              >
                Sign in
              </Button>
            </Stack>
          </chakra.form>
        </Card>
      </Box>
    </Box>
  );
}
