import "./Settings.css";

import { API, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { onError } from "../libs/errorLib";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import Payment from "./Payment";
import {
  Box,
  Text,
  useColorModeValue,
  FormControl,
  Heading,
  Link,
  useColorModeValue as mode,
  useToast,
  Stack,
  FormHelperText,
  FormLabel,
  HStack,
  Button,
  Input,
} from "@chakra-ui/react";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function Settings(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState(1);
  const toast = useToast();

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    function loadAccounts() {
      return API.get("accounts", "/accounts");
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        const accounts = await loadAccounts();
        setAccounts(accounts.length);
        setProfile(profile);
        setIsLoading(true);
        setUser(user);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  function handleFieldChange(evt) {
    setAccounts(parseInt(evt.target.value));
  }

  function makePayment(payment) {
    return API.post("profiles", "/addaccounts", {
      body: payment,
    });
  }

  async function handlePayment(event) {
    event.preventDefault();

    setIsLoading(false);

    const subscriptionId = profile.subscriptionId;
    const profileId = profile.profileId;
    let accountsToPurchase = accounts;
    if (profile.accountsPurchased)
      accountsToPurchase = profile.accountsPurchased + accounts;

    const payment = {
      subscriptionId,
      accounts: accountsToPurchase,
      profileId,
      version: "conversify",
    };

    try {
      await makePayment(payment);
      setIsLoading(true);
      toast({
        title: "Thank you!",
        description: "Let us know if you need help with anything.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "There was an error processing your payment.",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
      onError(e);
    }
  }

  function handlePaymentValidation() {
    return accounts >= 1;
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="24"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Heading textAlign="center" size="2xl" fontWeight="extrabold">
          Subscribe for unlimited access
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Not in the right spot?</Text>{" "}
          <Link
            as="a"
            href="/dashboard"
            color={mode("blue.600", "blue.200")}
            display={{
              base: "block",
              md: "inline-block",
            }}
          >
            Back to dashboard.
          </Link>
        </Text>
        <Card>
          {!isLoading ? (
            <IosRefresh fontSize="60px" color="#0085ef" rotate={true} />
          ) : profile.paid ? (
            <Stack>
              <FormControl>
                <FormLabel>
                  How many accounts would you like to add to your susbcription.
                </FormLabel>
                <Input
                  type="number"
                  value={accounts}
                  onChange={handleFieldChange}
                />
                <FormHelperText>
                  You will pay $
                  {(profile.accountsPurchased
                    ? profile.accountsPurchased + accounts
                    : accounts) * 59}{" "}
                  USD per month after purchase
                </FormHelperText>
              </FormControl>
              <br />
              <HStack>
                <Button
                  size="md"
                  colorScheme="blue"
                  className="btn-secondary"
                  onClick={handlePayment}
                  disabled={!handlePaymentValidation()}
                >
                  Purchase {accounts === 1 ? "account" : "accounts"}
                </Button>
              </HStack>
              <br />
              <hr />
              <br />
              <Text fontSize={"xs"}>
                Please email david@conversify.ai if {"you'd"} like to cancel.{" "}
              </Text>
            </Stack>
          ) : (
            <div className="text-center">
              <Payment
                numOfAccounts={accounts}
                props={props}
                user={user}
                profileId={profile.profileId}
              />
            </div>
          )}
        </Card>
      </Box>
    </Box>
  );
}
