import "./App.css";

import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Auth, API } from "aws-amplify";
import Routes from "./Routes";

import { ChakraProvider } from "@chakra-ui/react";

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();

      userHasAuthenticated(true);
    } catch (e) {
      console.log(e);
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    props.history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App">
        {/* {isAuthenticated ? null : (
          <Navbar
            style={{
              background: "white",
            }}
            collapseOnSelect
          >
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/r/home">
                  <img
                    height="30"
                    alt="conversify logo"
                    src="https://conversify-marketing.s3.amazonaws.com/conversify.png"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              {isAuthenticated ? null : (
                <Nav pullRight>
                  <LinkContainer to="/login">
                    <NavItem>Log In</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/r/nav">
                    <NavItem>Sign Up</NavItem>
                  </LinkContainer>
                </Nav>
              )}
            </Navbar.Collapse>
          </Navbar>
        )} */}
        <ChakraProvider>
          <Routes
            appProps={{
              isAuthenticated,
              userHasAuthenticated,
              handleLogout,
            }}
          />
        </ChakraProvider>
      </div>
    )
  );
}

export default withRouter(App);

window.Auth = Auth;
window.API = API;
