import {
  Box,
  Text,
  useColorModeValue,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  HStack,
  Icon,
  Flex,
  IconButton,
  Skeleton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { onError } from '../libs/errorLib';
import { API, Auth } from 'aws-amplify';
import {
  FiBookOpen,
  FiLinkedin,
  FiArrowLeft,
  FiPlus,
} from 'react-icons/fi';
import Contacts from './Contacts';
import Campaigns from './Campaigns';
import AccountSettings from '../components/AccountSettings';
import LinkedInSettings from './LinkedInSettings';
import ImportContactsModal from './ImportContactsModal';
import SubscribeBanner from './SubscribeBanner';
import ReactPlayer from 'react-player/youtube';

const ColumnHeader = (props) => (
  <Flex
    minH="12"
    position="sticky"
    zIndex={1}
    top="0"
    px="3"
    align="center"
    bg={useColorModeValue('white', 'gray.800')}
    color={useColorModeValue('gray.700', 'white')}
    {...props}
  />
);
const ColumnHeading = (props) => (
  <Heading
    fontWeight="bold"
    fontSize="sm"
    lineHeight="1.25rem"
    {...props}
  />
);

const NavLink = (props) => {
  const { url, icon, ...linkProps } = props;
  return (
    <Link
      as="a"
      px="2"
      py="1.5"
      borderRadius="md"
      _hover={{
        bg: mode('gray.100', 'gray.700'),
      }}
      _activeLink={{
        bg: 'gray.700',
        color: 'white',
      }}
      href={url}
      {...linkProps}
    >
      <HStack justify="space-between">
        <HStack spacing="3">
          <Icon as={icon} />

          <Text as="span" fontSize="sm" lineHeight="1.25rem">
            {props.children}
          </Text>
        </HStack>
      </HStack>
    </Link>
  );
};
const NavHeading = (props) => (
  <Text
    as="h4"
    fontSize="xs"
    fontWeight="semibold"
    px="2"
    lineHeight="1.25"
    color={mode('gray.600', 'gray.400')}
    {...props}
  />
);

const ColumnIconButton = (props) => (
  <IconButton
    size="sm"
    fontSize="md"
    variant="ghost"
    _hover={{
      bg: useColorModeValue('gray.100', 'gray.700'),
    }}
    _active={{
      bg: useColorModeValue('gray.200', 'gray.600'),
    }}
    _focus={{
      boxShadow: 'none',
    }}
    _focusVisible={{
      boxShadow: 'outline',
    }}
    {...props}
  />
);

export default function ViewAccount(props) {
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [screen, setScreen] = useState('Campaigns');
  const [campaigns, setCampaigns] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    function loadAccount() {
      return API.get(
        'accounts',
        `/accounts/${props.match.params.id}`
      );
    }

    function loadAccounts() {
      return API.get('accounts', '/accounts');
    }

    function loadCampaigns() {
      return API.get('campaigns', '/campaigns');
    }

    function loadProfile(userId) {
      return API.get('profiles', `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {
        setIsLoading(true);
        const accounts = await loadAccounts();
        const account = await loadAccount();
        const campaigns = await loadCampaigns();
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user['id']);
        setProfile(profile);
        setCampaigns(campaigns);
        setAccounts(accounts);
        if (account) {
          setAccount(account);
          if (
            'loginError' in account &&
            (account.loginError === 'pin.required' ||
              account.loginError === 'pin.missing' ||
              account.loginError === '2fa.missing' ||
              account.loginError === '2fa.required')
          ) {
            setLoginError('pin');
          }

          if (
            'loginError' in account &&
            account.loginError === 'credentials.invalid'
          ) {
            setLoginError('creds');
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function deleteAccount() {
    return API.del('accounts', `/accounts/${account.accountId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      'Are you sure you want to delete this account?'
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);

    try {
      await deleteAccount();
      props.history.push(`/dashboard`);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <>
      <SubscribeBanner />
      {loginError === 'creds' ? (
        <Box
          py="24"
          px={{
            base: '4',
            lg: '8',
          }}
        >
          <Box maxW="md" mx="auto">
            <Heading
              textAlign="center"
              size="xl"
              fontWeight="extrabold"
            >
              Incorrect password
            </Heading>
            <Text
              mt="6"
              mb="8"
              align="center"
              maxW="md"
              fontWeight="medium"
            >
              <Text as="span">
                That's alright,{' '}
                <Link
                  as="a"
                  href={`/accounts/${account.accountId}/edit`}
                  color={mode('blue.600', 'blue.200')}
                  display={{
                    base: 'block',
                    md: 'inline-block',
                  }}
                >
                  try again with a new password.
                </Link>
              </Text>{' '}
            </Text>
            <Text
              mt="4"
              mb="8"
              align="center"
              maxW="md"
              fontWeight="medium"
            >
              Is {account.email} the right email?{' '}
              <Link
                onClick={(e) => handleDelete(e)}
                color={mode('blue.600', 'blue.200')}
                display={{
                  base: 'block',
                  md: 'inline-block',
                }}
              >
                No, delete this account
              </Link>
            </Text>
          </Box>
        </Box>
      ) : account && account.firstName === 'none' ? (
        <Box py="20">
          <Box maxW="2xl" mx="auto">
            <Heading
              width={'full'}
              textAlign="center"
              size="2xl"
              fontWeight="extrabold"
            >
              Almost there ...
            </Heading>
            <Text
              mt="6"
              mb="8"
              margin="auto"
              textAlign="center"
              maxW="lg"
              fontWeight="medium"
            >
              Watch this video before creating your free campaign.
              When you're ready, message the live chat to get started.
            </Text>
            <br />
            <ReactPlayer
              style={{ width: '100% !important', margin: 'auto' }}
              url="https://youtu.be/3Xzao9mYSJA"
            />
          </Box>
        </Box>
      ) : (
        <Flex h={'100vh'}>
          <Box
            height="full"
            width={{
              md: '14rem',
              xl: '18rem',
            }}
            display={{
              base: 'none',
              lg: 'initial',
            }}
            overflowY="auto"
            borderRightWidth="1px"
          >
            <Flex
              height="full"
              direction="column"
              justify="space-between"
              {...props}
            >
              <Stack spacing="3">
                <Stack px="4" mt="3" spacing="6">
                  <Stack spacing="3">
                    <AccountSettings props={props} />
                    <NavHeading>LinkedIn Accounts</NavHeading>
                    <Stack spacing="1">
                      <NavLink icon={FiPlus} url={`/accounts/new`}>
                        New Account
                      </NavLink>
                      {accounts &&
                        accounts
                          .filter(
                            (account) => account.firstName !== 'none'
                          )
                          .map((account, i) => (
                            <NavLink
                              key={i}
                              icon={FiLinkedin}
                              url={`/accounts/${account.accountId}`}
                              aria-current={
                                props.match.params.id ===
                                  account.accountId && 'page'
                              }
                            >
                              {account.firstName} {account.lastName}
                            </NavLink>
                          ))}
                    </Stack>
                  </Stack>

                  <Stack spacing="3">
                    <NavHeading>Templates</NavHeading>
                    <Stack spacing="1">
                      <NavLink icon={FiPlus} url={`/templates/new`}>
                        New Template
                      </NavLink>
                      <NavLink url="/templates" icon={FiBookOpen}>
                        Template Library
                      </NavLink>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              {
                //   <Box borderTopWidth="1px">
                //   <NavButton>Settings</NavButton>
                // </Box>
              }
            </Flex>
          </Box>
          <Box
            borderRightWidth="1px"
            width={{
              md: '14rem',
              xl: '18rem',
            }}
            display={{
              base: 'none',
              md: 'initial',
            }}
            overflowY="auto"
            onScroll={(x) =>
              setSidebarIsScrolled(x.currentTarget.scrollTop > 32)
            }
          >
            <ColumnHeader
              shadow={sidebarIsScrolled ? 'base' : 'none'}
            >
              <HStack justify="space-between" width="full">
                {isLoading && (
                  <Skeleton height="25px" w="full" my={2} />
                )}
                {account && (
                  <ColumnHeading>
                    <HStack as="a" spacing="3">
                      <Icon as={FiLinkedin} />
                      <Text
                        as="span"
                        fontSize="md"
                        lineHeight="1.25rem"
                      >
                        {account.firstName} {account.lastName}
                      </Text>
                    </HStack>
                  </ColumnHeading>
                )}
              </HStack>
            </ColumnHeader>
            <Stack
              spacing={{
                base: '1px',
                lg: '1',
              }}
              px={{
                lg: '3',
              }}
              py="3"
              {...props}
            >
              {['Campaigns', 'Contacts', 'Settings'].map((val, i) => (
                <Link
                  key={i}
                  aria-current={screen === val ? 'page' : undefined}
                  _hover={{
                    textDecoration: 'none',
                    bg: mode('gray.100', 'gray.700'),
                  }}
                  _activeLink={{
                    bg: 'gray.700',
                    color: 'white',
                  }}
                  borderRadius={{
                    lg: 'lg',
                  }}
                  onClick={() => setScreen(val)}
                >
                  <Stack
                    py={{
                      base: '3',
                      lg: '2',
                    }}
                    px={{
                      base: '3.5',
                      lg: '3',
                    }}
                    fontSize="sm"
                    lineHeight="1.25rem"
                    spacing="4"
                    direction={{ base: 'column', md: 'row' }}
                    justify="space-between"
                  >
                    <Text fontWeight="medium">{val}</Text>
                  </Stack>
                </Link>
              ))}
            </Stack>
          </Box>

          <Box flex="1" overflowY="auto">
            <Box as="main">
              <Stack spacing="0">
                <Stack spacing="3">
                  <ColumnHeader shadow={'base'}>
                    <HStack justify="space-between" width="full">
                      <HStack spacing="3">
                        <ColumnIconButton
                          aria-label="Navigate back"
                          icon={<FiArrowLeft />}
                          display={{
                            base: 'inline-flex',
                            md: 'none',
                          }}
                        />
                        <ColumnHeading>{screen}</ColumnHeading>
                      </HStack>
                      {profile &&
                        profile.paid &&
                        screen === 'Campaigns' && (
                          <ImportContactsModal
                            account={account}
                            setScreen={setScreen}
                          />
                        )}
                    </HStack>
                  </ColumnHeader>
                </Stack>
                {screen === 'Contacts' ? (
                  <Contacts
                    campaigns={campaigns}
                    account={account}
                    setScreen={setScreen}
                    accountId={props.match.params.id}
                  />
                ) : null}
                {screen === 'Campaigns' ? (
                  <Campaigns
                    account={account}
                    setScreen={setScreen}
                    accountId={props.match.params.id}
                    campaigns={campaigns}
                  />
                ) : null}
                {screen === 'Settings' ? (
                  <LinkedInSettings
                    account={account}
                    handleDelete={handleDelete}
                  />
                ) : null}
              </Stack>
            </Box>
          </Box>
        </Flex>
      )}
    </>
  );
}
