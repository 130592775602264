import {
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormHelperText,
  Button,
  HStack,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { HiChevronDown } from "react-icons/hi";

export default function ViewTemplateItem(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [connectionRequestText, setConnectionRequestText] = useState("");
  const [firstFollowUpText, setFirstFollowUpText] = useState("none");
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [hasFirstFollowup, setHasFirstFollowup] = useState(false);
  const [secondFollowUpText, setSecondFollowUpText] = useState("none");
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [thirdFollowUpText, setThirdFollowUpText] = useState("none");
  const [thirdFollowUpDays, setThirdFollowUpDays] = useState(2);
  const [hasThirdFollowup, setHasThirdFollowup] = useState(false);
  const [fourthFollowUpText, setFourthFollowUpText] = useState("none");
  const [fourthFollowUpDays, setFourthFollowUpDays] = useState(2);
  const [hasFourthFollowup, setHasFourthFollowup] = useState(false);
  const [fifthFollowUpText, setFifthFollowUpText] = useState("none");
  const [fifthFollowUpDays, setFifthFollowUpDays] = useState(2);
  const [hasFifthFollowup, setHasFifthFollowup] = useState(false);
  const [sixthFollowUpText, setSixthFollowUpText] = useState("none");
  const [sixthFollowUpDays, setSixthFollowUpDays] = useState(2);
  const [hasSixthFollowup, setHasSixthFollowup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadTemplate() {
      return API.get("templates", `/templates/${props.templateId}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate();
        if (template) {
          setTitle(template.title);
          setDescription(template.description);
          setConnectionRequestText(template.connectionRequestText);
          "firstFollowUpText" in template
            ? setFirstFollowUpText(template.firstFollowUpText)
            : setFirstFollowUpText("none");
          "firstFollowUpDays" in template
            ? setFirstFollowUpDays(template.firstFollowUpDays)
            : setFirstFollowUpDays("none");
          "hasFirstFollowup" in template
            ? setHasFirstFollowup(template.hasFirstFollowup)
            : setHasFirstFollowup(false);
          "secondFollowUpText" in template
            ? setSecondFollowUpText(template.secondFollowUpText)
            : setSecondFollowUpText("none");
          "secondFollowUpDays" in template
            ? setSecondFollowUpDays(template.secondFollowUpDays)
            : setSecondFollowUpDays("none");
          "hasSecondFollowup" in template
            ? setHasSecondFollowup(template.hasSecondFollowup)
            : setHasSecondFollowup(false);

          "thirdFollowUpText" in template
            ? setThirdFollowUpText(template.thirdFollowUpText)
            : setThirdFollowUpText("none");
          "thirdFollowUpDays" in template
            ? setThirdFollowUpDays(template.thirdFollowUpDays)
            : setThirdFollowUpDays("none");
          "hasThirdFollowup" in template
            ? setHasThirdFollowup(template.hasThirdFollowup)
            : setHasThirdFollowup(false);

          "fourthFollowUpText" in template
            ? setFourthFollowUpText(template.fourthFollowUpText)
            : setFourthFollowUpText("none");
          "fourthFollowUpDays" in template
            ? setFourthFollowUpDays(template.fourthFollowUpDays)
            : setFourthFollowUpDays("none");
          "hasFourthFollowup" in template
            ? setHasFourthFollowup(template.hasFourthFollowup)
            : setHasFourthFollowup(false);

          "fifthFollowUpText" in template
            ? setFifthFollowUpText(template.fifthFollowUpText)
            : setFifthFollowUpText("none");
          "fifthFollowUpDays" in template
            ? setFifthFollowUpDays(template.fifthFollowUpDays)
            : setFifthFollowUpDays("none");
          "hasFifthFollowup" in template
            ? setHasFifthFollowup(template.hasFifthFollowup)
            : setHasFifthFollowup(false);

          "sixthFollowUpText" in template
            ? setSixthFollowUpText(template.sixthFollowUpText)
            : setSixthFollowUpText("none");
          "sixthFollowUpDays" in template
            ? setSixthFollowUpDays(template.sixthFollowUpDays)
            : setSixthFollowUpDays("none");
          "hasSixthFollowup" in template
            ? setHasSixthFollowup(template.hasSixthFollowup)
            : setHasSixthFollowup(false);
          setIsLoading(false);
        }
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [props.templateId]);

  function loadContacts(ExclusiveStartKey, campaignId) {
    return API.post("contacts", `/campaigncontacts`, {
      body: {
        campaignId,
        ExclusiveStartKey,
      },
    });
  }

  function updateContactsInCampaign(body) {
    return API.post("contacts", `/udpatecampaigncontacts`, {
      body,
    });
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  function saveTemplate(template) {
    return API.put("templates", `/templates/${props.templateId}`, {
      body: template,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    let contactsToUpload = [];
    let res;
    let ExclusiveStartKey = null;
    do {
      res = await loadContacts(ExclusiveStartKey, props.campaignId);
      res.Items.forEach((item) => contactsToUpload.push(item));
      ExclusiveStartKey = res.LastEvaluatedKey;
    } while (typeof res.LastEvaluatedKey != "undefined");

    const template = {
      title,
      description,
      connectionRequestText,
      firstFollowUpText,
      firstFollowUpDays,
      hasFirstFollowup,
      secondFollowUpText,
      secondFollowUpDays,
      hasSecondFollowup,
      thirdFollowUpText,
      thirdFollowUpDays,
      hasThirdFollowup,
      fourthFollowUpText,
      fourthFollowUpDays,
      hasFourthFollowup,
      fifthFollowUpText,
      fifthFollowUpDays,
      hasFifthFollowup,
      sixthFollowUpText,
      sixthFollowUpDays,
      hasSixthFollowup,
    };

    try {
      await saveTemplate(template);
      setIsLoading(false);
    } catch (e) {}

    try {
      var i,
        j,
        contacts,
        chunk = 25;
      for (i = 0, j = contactsToUpload.length; i < j; i += chunk) {
        contacts = contactsToUpload.slice(i, i + chunk);
        await updateContactsInCampaign({ template, contacts });
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <>
      <chakra.form onSubmit={handleSubmit}>
        <Stack spacing="6">
          <FormControl id="email">
            <FormLabel>Connection Request</FormLabel>
            <Textarea
              value={connectionRequestText}
              onChange={(e) => setConnectionRequestText(e.target.value)}
            />
            <FormHelperText>
              Connection request must be 300 characters or less.
            </FormHelperText>
          </FormControl>
          <FormControl id="password">
            <FormLabel mb={1}>Send Follow Up?</FormLabel>

            <input
              checked={hasFirstFollowup}
              onChange={handleFirstFollowup}
              type="checkbox"
            />
          </FormControl>
          {hasFirstFollowup && hasFirstFollowup ? (
            <div>
              <FormControl>
                <FormLabel>
                  How many days should we wait until we send the first
                  follow-up?
                </FormLabel>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        size="xs"
                        isActive={isOpen}
                        as={Button}
                        rightIcon={<HiChevronDown />}
                      >
                        {firstFollowUpDays}
                      </MenuButton>
                      <MenuList>
                        {[1, 2, 3, 5, 6, 7].map((day, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => setFirstFollowUpDays(day)}
                          >
                            {day}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </FormControl>
              <br />
              <FormControl controlId="firstFollowUpText">
                <FormLabel>Followup Message</FormLabel>
                <Textarea
                  value={firstFollowUpText}
                  onChange={(e) => setFirstFollowUpText(e.target.value)}
                />
              </FormControl>
              <br />
              <FormControl controlId="hasSecondFollowup">
                <FormLabel>Send Another Follow Up?</FormLabel>
                <input
                  checked={hasSecondFollowup}
                  onChange={handleSecondFollowup}
                  type="checkbox"
                />
              </FormControl>
            </div>
          ) : null}
          {hasSecondFollowup && hasSecondFollowup ? (
            <div>
              <FormControl>
                <FormLabel>
                  How many days should we wait until we send the second
                  follow-up?
                </FormLabel>
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton
                        size="xs"
                        isActive={isOpen}
                        as={Button}
                        rightIcon={<HiChevronDown />}
                      >
                        {secondFollowUpDays}
                      </MenuButton>
                      <MenuList>
                        {[1, 2, 3, 5, 6, 7].map((day, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => setSecondFollowUpDays(day)}
                          >
                            {day}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </FormControl>
              <br />
              <FormControl controlId="secondFollowUpText">
                <FormLabel>Second Followup Message</FormLabel>
                <Textarea
                  value={secondFollowUpText}
                  onChange={(e) => setSecondFollowUpText(e.target.value)}
                />
              </FormControl>
            </div>
          ) : null}
          {hasThirdFollowup && hasThirdFollowup ? (
            <div>
              <FormControl controlId="thirdFollowUpDays">
                <FormLabel>How many days after?</FormLabel>
                <Input
                  value={thirdFollowUpDays}
                  type="number"
                  onChange={(e) => setThirdFollowUpDays(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl controlId="thirdFollowUpText">
                <FormLabel>Third Followup Message</FormLabel>
                <Textarea
                  value={thirdFollowUpText}
                  onChange={(e) => setThirdFollowUpText(e.target.value)}
                />
              </FormControl>
            </div>
          ) : null}
          {hasFourthFollowup && hasFourthFollowup ? (
            <div>
              <FormControl controlId="fourthFollowUpDays">
                <FormLabel>How many days after?</FormLabel>
                <Input
                  value={fourthFollowUpDays}
                  type="number"
                  onChange={(e) => setFourthFollowUpDays(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl controlId="fourthFollowUpText">
                <FormLabel>Fourth Followup Message</FormLabel>
                <Textarea
                  value={fourthFollowUpText}
                  onChange={(e) => setFourthFollowUpText(e.target.value)}
                />
              </FormControl>
            </div>
          ) : null}
          {hasFifthFollowup && hasFifthFollowup ? (
            <div>
              <FormControl controlId="fifthFollowUpDays">
                <FormLabel>How many days after?</FormLabel>
                <Input
                  value={fifthFollowUpDays}
                  type="number"
                  onChange={(e) => setFifthFollowUpDays(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl controlId="fifthFollowUpText">
                <FormLabel>Fifth Followup Message</FormLabel>
                <Textarea
                  value={fifthFollowUpText}
                  onChange={(e) => setFifthFollowUpText(e.target.value)}
                />
              </FormControl>
            </div>
          ) : null}
          {hasSixthFollowup && hasSixthFollowup ? (
            <div>
              <FormControl controlId="sixthFollowUpDays">
                <FormLabel>How many days after?</FormLabel>
                <Input
                  value={sixthFollowUpDays}
                  type="number"
                  onChange={(e) => setSixthFollowUpDays(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl controlId="sixthFollowUpText">
                <FormLabel>Sixth Followup Message</FormLabel>
                <Textarea
                  value={sixthFollowUpText}
                  onChange={(e) => setSixthFollowUpText(e.target.value)}
                />
              </FormControl>
            </div>
          ) : null}

          <HStack>
            <Button type="submit" isLoading={isLoading} colorScheme={"blue"}>
              Update messages
            </Button>
          </HStack>
        </Stack>
      </chakra.form>
    </>
  );
}
