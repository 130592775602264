import { Box, Stack, Text, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import CampaignsSkeleton from "./CampaignsSkeleton";
import CampaignsTable from "./CampaignsTable";
import ImportContactsModal from "./ImportContactsModal";

export default function Campaigns({ accountId, account, setScreen }) {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      try {
        const campaigns = await loadCampaigns();
        setCampaigns(
          campaigns.filter((campaign) => campaign.accountId === accountId)
        );
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [accountId]);

  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  function renderCampaigns() {
    return (
      <Box>
        {campaigns.length > 0 ? (
          <CampaignsTable campaigns={campaigns} setCampaigns={setCampaigns} />
        ) : (
          <Stack px={6} mt={4} spacing="3">
            <Text maxW={"650px"}>
              LinkedIn campaigns make your life easier by allowing you to reach
              more prospects in less time. Each campaign has one connection
              request message and up to 2 follow-up messages. We will only send
              follow-up messages if someone connects with you and doesn't reply.
              If a prospect replies, it's on you to continue the conversation.
              You'll have to import contacts before you create a campaign.
            </Text>
            <HStack>
              <ImportContactsModal account={account} setScreen={setScreen} />
            </HStack>
          </Stack>
        )}
      </Box>
    );
  }

  return (
    <div className="">
      {isLoading ? <CampaignsSkeleton /> : renderCampaigns()}
    </div>
  );
}
