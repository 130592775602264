import {
  Box,
  Text,
  useColorModeValue,
  FormControl,
  FormLabel,
  Stack,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  AlertDescription,
  HStack,
  FormHelperText,
  Textarea,
  IconButton,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { Step } from "./ProgressSteps/Step";
import { StepContent } from "./ProgressSteps/StepContent";
import { Steps } from "./ProgressSteps/Steps";
import { useSteps } from "./ProgressSteps/useSteps";
import { HiChevronLeft, HiChevronRight, HiChevronDown } from "react-icons/hi";

const Card = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py="8"
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded={{
      sm: "lg",
    }}
    {...props}
  />
);

export default function NewTemplate(props) {
  const [title, setTitle] = useState("");
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const [connectionRequestText, setConnectionRequestText] = useState(
    "Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!"
  );
  const [firstFollowUpText, setFirstFollowUpText] = useState(
    "Thanks for connecting, {{firstName}}. Would love to get your advice on something I'm working on."
  );
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [secondFollowUpText, setSecondFollowUpText] = useState(null);
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const crSsuggestions = [
    "Hi {{firstName}}, I’m launching a (free) platform later this month that might interest you. If you’d like to see more, let’s connect and I’ll show you.",
    "Hi {{firstName}}, this may sound random but I'll ask anyway - do you golf?",
    "Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!",
    "Hi {{firstName}}, I'm inviting a few {{company}} employees to a webinar we are hosting at the end of the month. Would you like me to add your name to the list?",
    "Hey {{firstName}}, We’ve never met but I am co-founder at one of {{location}}'s top growth agencies. I created a personalized video for you breaking down some ideas for your growth and ad strategy. Cool if I send it over?",
    "Hi {{firstName}}, had some ideas around ambassador marketing. Thought this would be of interest to you, be great to connect.",
    "Hi {{firstName}}, I came across your profile today and would love to connect! I’ve partnered up with quite a few {{company}} employees across the country to help them with their finances.",
    "Hi {{firstName}}, suit guessing on LinkedIn is one of my favourite games. Is that a Harry Rosen suit you're wearing in your profile pic?",
    "Hi {{firstName}}, {{company}} came up in my research when looking for innovative teams that use {{custom1}}. I was wondering if I could get your feedback on a tool we've been building? I'm just trying to understand if there is a market for a what we're building.",
    "Hi {{firstName}}, have you ever wanted to learn how to code? I'm hosting an online workshop next week where I'll show people automate their LinkedIn account.",
  ];
  const [requestIndex, setRequestIndex] = useState(0);
  const [requestTextIndex, setRequestTextIndex] = useState(0);
  const [firstFollowupTextIndex, setFirstFollowupTextIndex] = useState(0);
  const [secondFollowupTextIndex, setSecondFollowupTextIndex] = useState(0);
  const personalizationVariables = [
    "{{firstName}}",
    "{{lastName}}",
    "{{company}}",
    "{{position}}",
    "{{custom1}}",
    "{{custom2}}",
  ];

  function showNextRequestNoteTemplate() {
    let newRequestIndex = requestIndex;
    const lastIndex = crSsuggestions.length - 1;
    if (newRequestIndex === 0) {
      newRequestIndex = lastIndex;
    } else {
      newRequestIndex -= 1;
    }
    setConnectionRequestText(crSsuggestions[newRequestIndex]);
    setRequestIndex(newRequestIndex);
  }

  function showPreviousRequestNoteTemplate() {
    let newRequestIndex = requestIndex;
    const lastIndex = crSsuggestions.length - 1;
    if (newRequestIndex === lastIndex) {
      newRequestIndex = 0;
    } else {
      newRequestIndex += 1;
    }
    setConnectionRequestText(crSsuggestions[newRequestIndex]);
    setRequestIndex(newRequestIndex);
  }

  async function handleCreateTemplate() {
    setIsLoading(true);

    const template = {
      title,
      description: "n/a",
      connectionRequestText,
      firstFollowUpText,
      firstFollowUpDays,
      hasFirstFollowup: firstFollowUpText && firstFollowUpText.length > 0,
      secondFollowUpText,
      secondFollowUpDays,
      hasSecondFollowup: secondFollowUpText && secondFollowUpText.length > 0,
      thirdFollowUpText: null,
      thirdFollowUpDays: 6,
      hasThirdFollowup: false,
      fourthFollowUpText: null,
      fourthFollowUpDays: 10,
      hasFourthFollowup: false,
      fifthFollowUpText: null,
      fifthFollowUpDays: 20,
      hasFifthFollowup: false,
      sixthFollowUpText: null,
      sixthFollowUpDays: 30,
      hasSixthFollowup: false,
    };

    try {
      await createTemplate(template);
      window.gtag("event", "conversion", {
        send_to: "AW-479656940/uDJ-CJ6M588CEOz32-QB",
      });
      props.history.push("/templates");
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
  }

  function createTemplate(template) {
    return API.post("templates", "/templates", {
      body: template,
    });
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="14"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="xl" mx="auto">
        <Heading textAlign="center" size="xl" fontWeight="extrabold">
          New Template
        </Heading>
        <Text mt="4" mb="8" align="center" maxW="xl" fontWeight="medium">
          Each template has connection request and up to two follow-up messages.
        </Text>
        <Card>
          <Steps activeStep={activeStep}>
            <Step title="Title">
              <StepContent>
                <Stack spacing="4">
                  <FormControl id="role">
                    <Input
                      value={title}
                      rows={3}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      placeholder="Cold Outreach"
                    />
                    <FormHelperText>
                      This is for your reference later.
                    </FormHelperText>
                  </FormControl>

                  <HStack>
                    <Button
                      size="sm"
                      isDisabled={title.length === 0}
                      onClick={() => nextStep()}
                    >
                      Save and continue
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="Connection Request">
              <StepContent>
                <Stack spacing="4">
                  <FormControl id="role">
                    <HStack
                      position={{ sm: "absolute" }}
                      top={{ sm: "0" }}
                      insetEnd={{ sm: "0" }}
                      mt={{ base: "4", sm: "0" }}
                    >
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              size="xs"
                              isActive={isOpen}
                              as={Button}
                              rightIcon={<HiChevronDown />}
                            >
                              personalize
                            </MenuButton>
                            <MenuList>
                              {personalizationVariables.map((variable, i) => (
                                <MenuItem
                                  onClick={() =>
                                    setConnectionRequestText(
                                      connectionRequestText.slice(
                                        0,
                                        requestTextIndex
                                      ) +
                                        variable +
                                        connectionRequestText.slice(
                                          requestTextIndex
                                        )
                                    )
                                  }
                                  key={i}
                                >
                                  {variable}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                      <IconButton
                        aria-label="Edit"
                        icon={<HiChevronLeft />}
                        rounded="full"
                        size="xs"
                        onClick={() => showPreviousRequestNoteTemplate()}
                      />
                      <IconButton
                        aria-label="Delete"
                        icon={<HiChevronRight />}
                        rounded="full"
                        size="xs"
                        onClick={() => showNextRequestNoteTemplate()}
                      />
                    </HStack>
                    <FormLabel>Connection request</FormLabel>
                    <Textarea
                      value={connectionRequestText}
                      rows={3}
                      size="xs"
                      onChange={(e) => {
                        setRequestTextIndex(e.target.selectionStart);
                        setConnectionRequestText(e.target.value);
                      }}
                      onBlur={(e) =>
                        setRequestTextIndex(e.target.selectionStart)
                      }
                      placeholder="Leave this blank if you want to exclude a note."
                    />
                    {connectionRequestText.length > 300 && (
                      <Alert mt={2} status="error">
                        <AlertIcon />
                        <AlertDescription mr={2}>
                          Connection request must be less than 300 characters
                        </AlertDescription>
                      </Alert>
                    )}
                  </FormControl>
                  <HStack>
                    <Button
                      size="sm"
                      onClick={prevStep}
                      variant="outline"
                      display="inline"
                    >
                      Back
                    </Button>
                    <Button
                      size="sm"
                      disabled={connectionRequestText.length > 300}
                      onClick={() => nextStep()}
                    >
                      Save and continue
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="Follow-ups">
              <StepContent>
                <Stack spacing="4">
                  <FormControl>
                    <FormLabel>
                      How many days should we wait until we send the first
                      follow-up?
                    </FormLabel>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            size="xs"
                            isActive={isOpen}
                            as={Button}
                            rightIcon={<HiChevronDown />}
                          >
                            {firstFollowUpDays}
                          </MenuButton>
                          <MenuList>
                            {[1, 2, 3, 5, 6, 7].map((day, i) => (
                              <MenuItem
                                key={i}
                                onClick={() => setFirstFollowUpDays(day)}
                              >
                                {day}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </FormControl>
                  <FormControl>
                    <HStack
                      position={{ sm: "absolute" }}
                      top={{ sm: "0" }}
                      insetEnd={{ sm: "0" }}
                      mt={{ base: "4", sm: "0" }}
                    >
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              size="xs"
                              isActive={isOpen}
                              as={Button}
                              rightIcon={<HiChevronDown />}
                            >
                              personalize
                            </MenuButton>
                            <MenuList>
                              {personalizationVariables.map((variable, i) => (
                                <MenuItem
                                  onClick={() =>
                                    setFirstFollowUpText(
                                      firstFollowUpText.slice(
                                        0,
                                        firstFollowupTextIndex
                                      ) +
                                        variable +
                                        firstFollowUpText.slice(
                                          firstFollowupTextIndex
                                        )
                                    )
                                  }
                                  key={i}
                                >
                                  {variable}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    </HStack>
                    <FormLabel size="xs">First follow-up message</FormLabel>
                    <Textarea
                      value={firstFollowUpText}
                      rows={6}
                      onChange={(e) => {
                        setFirstFollowUpText(e.target.value);
                        setFirstFollowupTextIndex(e.target.selectionStart);
                      }}
                      onBlur={(e) =>
                        setFirstFollowupTextIndex(e.target.selectionStart)
                      }
                      size="xs"
                      placeholder="Leave this blank and we won't send a follow-up message."
                    />
                    <FormHelperText>
                      {`We'll send this if someone connects with you and doesn't reply.`}
                    </FormHelperText>
                    {firstFollowUpText.length > 500 && (
                      <Alert mt={4} status="error">
                        <AlertIcon />
                        <AlertDescription mr={2}>
                          Follow-up must be less than 500 characters
                        </AlertDescription>
                      </Alert>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      How many days should we wait until we send the second
                      follow-up?
                    </FormLabel>
                    <Menu>
                      {({ isOpen }) => (
                        <>
                          <MenuButton
                            size="xs"
                            isActive={isOpen}
                            as={Button}
                            rightIcon={<HiChevronDown />}
                          >
                            {secondFollowUpDays}
                          </MenuButton>
                          <MenuList>
                            {[1, 2, 3, 5, 6, 7].map((day, i) => (
                              <MenuItem
                                key={i}
                                onClick={() => setSecondFollowUpDays(day)}
                              >
                                {day}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </>
                      )}
                    </Menu>
                  </FormControl>
                  <FormControl>
                    <HStack
                      position={{ sm: "absolute" }}
                      top={{ sm: "0" }}
                      insetEnd={{ sm: "0" }}
                      mt={{ base: "4", sm: "0" }}
                    >
                      <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              size="xs"
                              isActive={isOpen}
                              as={Button}
                              rightIcon={<HiChevronDown />}
                            >
                              personalize
                            </MenuButton>
                            <MenuList>
                              {personalizationVariables.map((variable, i) => (
                                <MenuItem
                                  onClick={() =>
                                    setSecondFollowUpText(
                                      secondFollowUpText.slice(
                                        0,
                                        secondFollowupTextIndex
                                      ) +
                                        variable +
                                        secondFollowUpText.slice(
                                          secondFollowupTextIndex
                                        )
                                    )
                                  }
                                  key={i}
                                >
                                  {variable}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </>
                        )}
                      </Menu>
                    </HStack>
                    <FormLabel size="xs">Second follow-up message</FormLabel>
                    <Textarea
                      value={secondFollowUpText}
                      rows={6}
                      onChange={(e) => {
                        setSecondFollowUpText(e.target.value);
                        setSecondFollowupTextIndex(e.target.selectionStart);
                      }}
                      onBlur={(e) =>
                        setSecondFollowupTextIndex(e.target.selectionStart)
                      }
                      size="xs"
                      placeholder="Leave this blank and we won't send a follow-up message."
                    />
                    <FormHelperText>
                      {`We'll send this if someone connects with you and doesn't reply.`}
                    </FormHelperText>
                    {secondFollowUpText && secondFollowUpText.length > 500 && (
                      <Alert mt={4} status="error">
                        <AlertIcon />
                        <AlertDescription mr={2}>
                          Follow-up must be less than 500 characters
                        </AlertDescription>
                      </Alert>
                    )}
                  </FormControl>
                  <HStack>
                    <Button
                      size="sm"
                      onClick={prevStep}
                      variant="outline"
                      display="inline"
                    >
                      Back
                    </Button>
                    <Button
                      size="sm"
                      disabled={connectionRequestText.length > 300}
                      onClick={() => nextStep()}
                    >
                      Save and continue
                    </Button>
                  </HStack>
                </Stack>
              </StepContent>
            </Step>
            <Step title="Preview">
              <StepContent>
                <Stack spacing="8">
                  <Stack spacing="2">
                    <Text fontWeight={"bold"}>Connection Request</Text>
                    <Text>{connectionRequestText}</Text>
                  </Stack>
                  {firstFollowUpText && firstFollowUpText.length > 0 && (
                    <Text color={"#4399e1"}>
                      Wait {firstFollowUpDays}{" "}
                      {firstFollowUpDays === 1 ? "day" : "days"}.
                    </Text>
                  )}
                  {firstFollowUpText && firstFollowUpText.length > 0 && (
                    <Stack spacing="2">
                      <Text fontWeight={"bold"}>First follow-up</Text>
                      <Text>{firstFollowUpText}</Text>
                    </Stack>
                  )}
                  {secondFollowUpText && secondFollowUpText.length > 0 && (
                    <Text color={"#4399e1"}>
                      Wait {secondFollowUpDays}{" "}
                      {secondFollowUpDays === 1 ? "day" : "days"}.
                    </Text>
                  )}
                  {secondFollowUpText && secondFollowUpText.length > 0 && (
                    <Stack spacing="2">
                      <Text fontWeight={"bold"}>Second follow-up</Text>
                      <Text>{secondFollowUpText}</Text>
                    </Stack>
                  )}
                  <HStack>
                    <Button
                      size="sm"
                      onClick={prevStep}
                      variant="outline"
                      display="inline"
                    >
                      Back
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => handleCreateTemplate()}
                      isLoading={isLoading}
                    >
                      Create template
                    </Button>
                  </HStack>{" "}
                </Stack>
              </StepContent>
            </Step>
          </Steps>
        </Card>
      </Box>
    </Box>
  );
}
