import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  useDisclosure,
  useToast,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Flex,
  Link,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { RiRefreshLine } from "react-icons/ri";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";

const SyncContactsModal = ({ setContacts, contacts, accountId }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState("");
  const [key, setKey] = useState("");
  const [hasClose, setHasClose] = useState(false);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    function loadAccount() {
      return API.get("accounts", `/accounts/${accountId}`);
    }

    async function onLoad() {
      const account = await loadAccount();
      setAccount(account);
      if (account && "closeId" in account) {
        setHasClose(true);
        setKey(account.closeId);
      }
    }

    onLoad();
  }, [accountId]);

  function saveAccount(account) {
    return API.put("accounts", `/accounts/${accountId}`, {
      body: account,
    });
  }

  async function updateAccountKey() {
    setIsLoading(true);

    try {
      const accountToSave = account;
      accountToSave["closeId"] = key;
      await saveAccount(accountToSave);
      setHasClose(true);
      setIsLoading(false);
      toast({
        title: "Close API key saved!",
        description:
          "If you need to update your key you can do it in your settings.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "There was an error adding your key.",
        description: "Please try again.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  function sync(contactToEnrich) {
    return API.post("accounts", "/requests", {
      body: contactToEnrich,
    });
  }

  async function syncContacts() {
    setIsLoading(true);
    toast({
      title: "Syncing in progress...",
      description: "Hold tight, this will take a few minutes.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    try {
      var i,
        j,
        contactsU,
        chunk = 25;
      for (i = 0, j = contacts.length; i < j; i += chunk) {
        contactsU = contacts.slice(i, i + chunk);
        await sync({ key, contacts: contactsU, account });
      }
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
    toast({
      title: "Syncing complete!",
      description: "Check Close for your udpated contacts.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    onClose();
  }

  return (
    <>
      <Button
        iconSpacing="1"
        borderColor="gray.300"
        background="#fff"
        leftIcon={<RiRefreshLine fontSize="1em" />}
        disabled={contacts.length === 0}
        onClick={onOpen}
      >
        Sync
      </Button>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pb={4}>
          <ModalHeader fontWeight="bold">
            Sync Contacts with Close CRM
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!hasClose ? (
              <Box w="full">
                <Text>
                  Close is an all-in-one CRM built just for sales teams. Our
                  integration lets you sync your Conversify data with Close.
                  You'll need to have{" "}
                  <Link
                    target="_blank"
                    color="pink.500"
                    href="https://app.close.com/settings/custom-fields/contact/"
                  >
                    four contact custom fields
                  </Link>{" "}
                  available for the integration to work.
                </Text>
                <FormControl mt={6}>
                  <Flex align="baseline" justify="space-between">
                    <FormLabel mb={1}>Close API Key</FormLabel>
                    <Link
                      as="a"
                      href="https://app.close.com/settings/api/"
                      fontWeight="semibold"
                      fontSize="sm"
                      color={mode("blue.600", "blue.200")}
                      target="_blank"
                    >
                      Find API Key
                    </Link>
                  </Flex>
                  <Input
                    autoFocus
                    type="text"
                    value={key}
                    my={2}
                    placeholder="api_4iVPZFrAvmtD0TTJCbYRmi.60isxqRBTnvN35KCiY"
                    onChange={(e) => setKey(e.target.value)}
                  />
                </FormControl>
                <HStack>
                  <Button
                    onClick={() => updateAccountKey()}
                    size="sm"
                    isLoading={isLoading}
                    mt={1}
                    isDisabled={key.length === 0}
                    colorScheme="blue"
                  >
                    Save Close API Key
                  </Button>
                </HStack>
              </Box>
            ) : (
              <Box w="full">
                <Text>
                  You've selected {contacts.length}{" "}
                  {contacts.length === 1 ? "contact" : "contacts"}. In Close,
                  you'll be able to keep track whether a contact has connected
                  or replied on LinkedIn.
                </Text>
                <HStack mt={2}>
                  <Button
                    onClick={() => syncContacts()}
                    size="sm"
                    isLoading={isLoading}
                    mt={1}
                    colorScheme="blue"
                  >
                    Sync contacts
                  </Button>
                </HStack>
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SyncContactsModal;
