import { Box, Text, Stack, Link, Button, HStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";

export default function ViewAccount() {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        setProfile(profile);
      } catch (e) {}
    }

    onLoad();
  }, []);

  return (
    <>
      {profile && !profile.paid && (
        <Box
          bg="blue.400"
          color="white"
          py="2"
          px={{ base: "3", md: "6", lg: "8" }}
        >
          <HStack spacing="3">
            <Stack
              direction={{ base: "column", sm: "row" }}
              justifyContent="center"
              alignItems="center"
              spacing={{ base: "3", md: "6" }}
              width="full"
            >
              <Text>
                <b>Your first import is free.</b> You can import unlimited data
                after you subscribe.
              </Text>
              <Link _hover={{ textDecoration: "none" }} href="/subscription">
                <Button
                  backgroundColor="white"
                  color="blue.400"
                  fontWeight="bold"
                  fontSize="sm"
                  py={1}
                  h="100%"
                  _active={{
                    bg: "blue.400",
                    transform: "scale(0.95)",
                  }}
                >
                  Subscribe
                </Button>
              </Link>
            </Stack>
          </HStack>
        </Box>
      )}
    </>
  );
}
