import { Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ChangeEmail from "./containers/ChangeEmail";
import ChangeName from "./containers/ChangeName";
import ChangePassword from "./containers/ChangePassword";
import Dashboard from "./containers/Dashboard";
import EditAccount from "./containers/EditAccount";
import GodView from "./containers/GodView";
import NotFound from "./containers/NotFound";
import React from "react";
import ResetPassword from "./containers/ResetPassword";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ViewAccount from "./containers/ViewAccount";
import SignupPage from "./containers/SignupPage";
import LoginPage from "containers/LoginPage/LoginPage";
import OnboardingPage from "containers/OnboardingPage";
import NewAccount from "containers/NewAccount";
import Templates from "containers/Templates";
import NewTemplate from "containers/NewTemplate";
import EditTemplate from "containers/EditTemplate";
import Subscription from "containers/Subscription";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <UnauthenticatedRoute
        path="/"
        exact
        component={SignupPage}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={LoginPage}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/r/:id"
        exact
        component={SignupPage}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login/reset"
        exact
        component={ResetPassword}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/new"
        exact
        component={NewAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/onboarding"
        exact
        component={OnboardingPage}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id"
        exact
        component={ViewAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id/edit"
        exact
        component={EditAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/conversifycampaigns"
        exact
        component={GodView}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/templates"
        exact
        component={Templates}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/templates/:id/edit"
        exact
        component={EditTemplate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/templates/new"
        exact
        component={NewTemplate}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/settings/email"
        exact
        component={ChangeEmail}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/settings/password"
        exact
        component={ChangePassword}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/settings/name"
        exact
        component={ChangeName}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/subscription"
        exact
        component={Subscription}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
