import { API } from "aws-amplify";

function loadContacts(ExclusiveStartKey, campaignId) {
  return API.post("contacts", `/campaigncontacts`, {
    body: {
      campaignId,
      ExclusiveStartKey,
    },
  });
}

export async function contactsInCamapign(campaignId) {
  let contacts = [];
  let res;
  let ExclusiveStartKey = null;
  do {
    res = await loadContacts(ExclusiveStartKey, campaignId);
    res.Items.forEach((item) => contacts.push(item));
    ExclusiveStartKey = res.LastEvaluatedKey;
  } while (typeof res.LastEvaluatedKey != "undefined");

  const allContacts = [];
  for (let contactIndex in contacts) {
    const thisContact = contacts[contactIndex];
    thisContact["First Name"] = thisContact.firstName;
    allContacts.push(thisContact);
  }

  return allContacts;
}
