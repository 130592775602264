import { Switch } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

const UpdateStatus = ({ campaign }) => {
  const [campaignPaused, setCampaignPaused] = useState(false);

  useEffect(() => {
    setCampaignPaused(campaign.paused);
  }, [campaign]);

  async function handleActivateCampaign(campaign) {
    const updatedCampaign = {
      title: campaign.title,
      dailyLimit: campaign.dailyLimit,
      paused: !campaign.paused,
      slug: campaign.slug,
      accountId: campaign.accountId,
      campaignId: campaign.campaignId,
      username: campaign.username,
      userId: campaign.userId,
      contacts: campaign.contacts,
    };

    try {
      await saveCampaign(updatedCampaign);
    } catch (e) {}
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${campaign.campaignId}`, {
      body: campaign,
    });
  }

  function toggleCampaignStatus() {
    setCampaignPaused(!campaignPaused);
    handleActivateCampaign(campaign);
  }

  return (
    <Switch
      colorScheme="blue"
      isChecked={!campaignPaused}
      id="email-alerts"
      onChange={() => toggleCampaignStatus()}
      mr={3}
    />
  );
};

export default UpdateStatus;
