import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Stack,
  Button,
  HStack,
  useDisclosure,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  Input,
  Text,
  Select,
  Alert,
  AlertIcon,
  AlertDescription,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  FormLabel,
  Textarea,
  FormHelperText,
  Badge,
} from '@chakra-ui/react';
import {
  HiChevronLeft,
  HiChevronRight,
  HiChevronDown,
} from 'react-icons/hi';
import { API, Auth } from 'aws-amplify';
import { Step } from './ProgressSteps/Step';
import { StepContent } from './ProgressSteps/StepContent';
import { Steps } from './ProgressSteps/Steps';
import { useSteps } from './ProgressSteps/useSteps';
import { RiAddFill } from 'react-icons/ri';
import { useState, useEffect } from 'react';
import { contactsInCamapign } from '../libs/contactsInCampaign';
import CSVReader from 'react-csv-reader';

const ImportContactsModal = ({ account, setScreen }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const [isLoading, setIsLoading] = useState('');
  const [contacts, setContacts] = useState([]);
  const [title, setTitle] = useState('');
  const [templates, setTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({
    title: 'Choose a template',
    connectionRequest: '',
    hasFirstFollowup: true,
    firstFollowUpDays: 1,
    hasSecondFollowup: true,
    secondFollowUpDays: 3,
    secondFollowUpText: '',
  });
  const [connectionRequest, setConnectionRequest] = useState('');
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [firstFollowUpText, setFirstFollowUpText] = useState('');
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(3);
  const [secondFollowUpText, setSecondFollowUpText] = useState('');
  const personalizationVariables = [
    '{{firstName}}',
    '{{lastName}}',
    '{{company}}',
    '{{position}}',
    '{{custom1}}',
    '{{custom2}}',
  ];
  const [requestTextIndex, setRequestTextIndex] = useState(0);
  const [requestIndex, setRequestIndex] = useState(0);
  const crSsuggestions = [
    'Hi {{firstName}}, I’m launching a (free) platform later this month that might interest you. If you’d like to see more, let’s connect and I’ll show you.',
    "Hi {{firstName}}, this may sound random but I'll ask anyway - do you golf?",
    'Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!',
    "Hi {{firstName}}, I'm inviting a few {{company}} employees to a webinar we are hosting at the end of the month. Would you like me to add your name to the list?",
    "Hey {{firstName}}, We’ve never met but I am co-founder at one of {{location}}'s top growth agencies. I created a personalized video for you breaking down some ideas for your growth and ad strategy. Cool if I send it over?",
    'Hi {{firstName}}, had some ideas around ambassador marketing. Thought this would be of interest to you, be great to connect.',
    'Hi {{firstName}}, I came across your profile today and would love to connect! I’ve partnered up with quite a few {{company}} employees across the country to help them with their finances.',
    "Hi {{firstName}}, suit guessing on LinkedIn is one of my favourite games. Is that a Harry Rosen suit you're wearing in your profile pic?",
    "Hi {{firstName}}, {{company}} came up in my research when looking for innovative teams that use {{custom1}}. I was wondering if I could get your feedback on a tool we've been building? I'm just trying to understand if there is a market for a what we're building.",
    "Hi {{firstName}}, have you ever wanted to learn how to code? I'm hosting an online workshop next week where I'll show people automate their LinkedIn account.",
  ];
  const [firstFollowupTextIndex, setFirstFollowupTextIndex] =
    useState(0);
  const [secondFollowupTextIndex, setSecondFollowupTextIndex] =
    useState(0);
  // const [keywords, setKeywords] = useState("");
  const [profile, setProfile] = useState(null);
  const [headingsError, setHeadingsError] = useState(false);
  // const [importedContacts, setImportedContacts] = useState(false);

  useEffect(() => {
    function loadTemplates() {
      return API.get('templates', '/templates');
    }

    function loadProfile(userId) {
      return API.get('profiles', `/myprofile/${userId}`);
    }

    async function onLoad() {
      let templates = await loadTemplates();
      templates = templates
        .map((template) => {
          const temp = template;
          if (template['title'])
            temp['title'] = template['title'].trim();
          return temp;
        })
        .filter((temp) => temp.title !== '');
      setTemplates(templates);
      const user = await Auth.currentUserInfo();
      const profile = await loadProfile(user['id']);
      setProfile(profile);
    }

    onLoad();
  }, []);

  // async function importSavedSearch() {
  //   setIsLoading(true);
  //   nextStep();
  //   toast({
  //     title: "Hold tight while we find your contacts.",
  //     description:
  //       "This will take a few minutes. Take that time to think about how you'd like to message them. Messaging them is optional.",
  //     status: "success",
  //     duration: 8000,
  //     isClosable: true,
  //   });
  //   try {
  //     const res = await axios.post(
  //       `https://legacy.api.conversify.ai/exportSavedSearchProfilesList?userId=${account.userId}&accountId=${account.accountId}&searchUrl=${savedSearchUrl}`
  //     );
  //     setContacts(res.data.profiles);
  //     setTitle(res.data.name);
  //     setIsLoading(false);
  //   } catch (e) {
  //     toast({
  //       title: "There was an error importing data from LinkedIn.",
  //       description: e.message,
  //       status: "error",
  //       duration: 4000,
  //       isClosable: true,
  //     });
  //     setIsLoading(false);
  //   }
  // }

  // async function importSearch() {
  //   setIsLoading(true);
  //   nextStep();
  //   toast({
  //     title: "Hold tight while we find your contacts.",
  //     description:
  //       "This will take a few minutes. Take that time to think about how you'd like to message them. Messaging them is optional.",
  //     status: "success",
  //     duration: 8000,
  //     isClosable: true,
  //   });
  //   try {
  //     const searchUrl = encodeURIComponent(
  //       `https://www.linkedin.com/search/results/people/?keywords=${keywords}&network=["S"]&origin=GLOBAL_SEARCH_HEADER&sid=vUb`
  //     );
  //     const res = await axios.post(
  //       `https://legacy.api.conversify.ai/exportSearchProfilesList?userId=${account.userId}&accountId=${account.accountId}&searchUrl=${searchUrl}`
  //     );
  //     setContacts(res.data.profiles);
  //     setTitle(keywords);
  //     setIsLoading(false);
  //     toast({
  //       title: "Your LinkedIn data is ready!",
  //       description: "Now you can find their emails and phone numbers.",
  //       status: "success",
  //       duration: 8000,
  //       isClosable: true,
  //     });
  //   } catch (e) {
  //     toast({
  //       title: "There was an error importing data from LinkedIn.",
  //       description: e.message,
  //       status: "error",
  //       duration: 4000,
  //       isClosable: true,
  //     });
  //     setIsLoading(false);
  //   }
  // }

  // async function importLeadList() {
  //   toast({
  //     title: "We got your info.",
  //     description: "We get you your contacts in the next 24 hours.",
  //     status: "success",
  //     duration: 8000,
  //     isClosable: true,
  //   });
  //   try {
  //     await createCampaign({
  //       title: listUrl,
  //       dailyLimit: 12,
  //       contacts: 0,
  //       accountId: account.accountId,
  //       profileId: profile.profileId,
  //     });
  //     setImportedContacts(true);
  //     setIsLoading(false);
  //   } catch (e) {
  //     console.log(e);
  //     toast({
  //       title: "There was an error importing data from LinkedIn.",
  //       description: e.message,
  //       status: "error",
  //       duration: 4000,
  //       isClosable: true,
  //     });
  //     setIsLoading(false);
  //   }
  // }

  function updateTemplate(templateTitle) {
    const chosenTemplate = templates.filter(
      (temp) => temp.title === templateTitle
    )[0];
    if (chosenTemplate) {
      setSelectedTemplate(chosenTemplate);
      setConnectionRequest(chosenTemplate.connectionRequestText);
      setFirstFollowUpDays(chosenTemplate.firstFollowUpDays);
      setFirstFollowUpText(chosenTemplate.firstFollowUpText);
      setSecondFollowUpDays(chosenTemplate.secondFollowUpDays);
      setSecondFollowUpText(chosenTemplate.secondFollowUpText);
    }
  }

  function showNextRequestNoteTemplate() {
    let newRequestIndex = requestIndex;
    const lastIndex = crSsuggestions.length - 1;
    if (newRequestIndex === 0) {
      newRequestIndex = lastIndex;
    } else {
      newRequestIndex -= 1;
    }
    setConnectionRequest(crSsuggestions[newRequestIndex]);
    setRequestIndex(newRequestIndex);
  }

  function showPreviousRequestNoteTemplate() {
    let newRequestIndex = requestIndex;
    const lastIndex = crSsuggestions.length - 1;
    if (newRequestIndex === lastIndex) {
      newRequestIndex = 0;
    } else {
      newRequestIndex += 1;
    }
    setConnectionRequest(crSsuggestions[newRequestIndex]);
    setRequestIndex(newRequestIndex);
  }

  function parseCopy(copy, index) {
    if (copy && copy.indexOf('{{') >= 0 && copy.indexOf('}}') >= 0) {
      const start_index = copy.indexOf('{{');
      const end_index = copy.indexOf('}}');
      const variable = copy.slice(start_index + 2, end_index);
      return parseCopy(
        copy.replace(
          copy.slice(start_index, end_index + 2),
          contacts[index][variable]
        ),
        index
      );
    }
    return copy;
  }

  function createTemplate(template) {
    return API.post('templates', '/templates', {
      body: template,
    });
  }
  function createCampaign(campaign) {
    return API.post('campaigns', '/campaigns', {
      body: campaign,
    });
  }

  function saveTemplate(template) {
    return API.put('templates', `/templates/${template.templateId}`, {
      body: template,
    });
  }

  function saveCampaign(updatedCampaign) {
    return API.put(
      'campaigns',
      `/campaigns/${updatedCampaign.campaignId}`,
      {
        body: updatedCampaign,
      }
    );
  }

  async function deleteCampaign(campaign) {
    return API.del('campaigns', `/campaigns/${campaign.campaignId}`);
  }

  function createContact(contacts) {
    return API.post('contacts', '/contacts', {
      body: contacts,
    });
  }

  function cleanName(name) {
    let cleanName = name;
    if (cleanName.includes(' ')) cleanName = cleanName.split(' ')[0];
    cleanName = cleanName.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    );

    return cleanName;
  }

  function handleForce(data) {
    let contacts = data;
    const titles = contacts[0];
    contacts = contacts.slice(1, contacts.length);
    const contactsToUpload = [];
    const firstNameIndex = titles.indexOf('firstName');
    const linkedinUrlIndex = titles.indexOf('linkedinUrl');
    const lastNameIndex = titles.indexOf('lastName');
    const companyIndex = titles.indexOf('company');
    const emailIndex = titles.indexOf('email');
    const phoneIndex = titles.indexOf('phone');
    const titleIndex = titles.indexOf('title');
    const custom1Index = titles.indexOf('custom1');
    const custom2Index = titles.indexOf('custom2');
    if (
      !titles.includes('firstName') ||
      !titles.includes('linkedinUrl')
    ) {
      setHeadingsError(true);
    } else {
      for (let i in contacts) {
        const c = contacts[i];
        const firstName = cleanName(c[firstNameIndex]);
        const linkedinUrl = c[linkedinUrlIndex];
        let lastName = 'x';
        if (lastNameIndex > 0 && c[lastNameIndex] !== '')
          lastName = c[lastNameIndex];
        let company = 'x';
        if (companyIndex > 0) company = c[companyIndex];
        let email = '';
        if (emailIndex > 0) email = c[emailIndex];
        let phone = '';
        if (phoneIndex > 0) phone = c[phoneIndex];
        let title = '';
        if (titleIndex > 0) title = c[titleIndex];
        let custom1 = '';
        if (custom1Index > 0) custom1 = c[custom1Index];
        let custom2 = '';
        if (custom2Index > 0) custom2 = c[custom2Index];
        const contactToUpload = {
          firstName,
          linkedinUrl,
          lastName,
          company,
          email,
          phone,
          title,
          custom1,
          custom2,
        };
        if (linkedinUrl && linkedinUrl !== '' && firstName !== '')
          contactsToUpload.push(contactToUpload);
      }
      setContacts(contactsToUpload);
      nextStep();
    }
  }

  async function handleCreateCampaign() {
    setIsLoading(true);

    let templateId = selectedTemplate.templateId;
    if (selectedTemplate.title === 'Choose a template') {
      const createdTemplate = await createTemplate({
        title,
        description: 'n/a',
        connectionRequestText: connectionRequest,
        firstFollowUpText,
        firstFollowUpDays,
        hasFirstFollowup:
          firstFollowUpText && firstFollowUpText.length > 0,
        secondFollowUpText,
        secondFollowUpDays,
        hasSecondFollowup:
          secondFollowUpText && secondFollowUpText.length > 0,
        thirdFollowUpText: null,
        thirdFollowUpDays: 6,
        hasThirdFollowup: false,
        fourthFollowUpText: null,
        fourthFollowUpDays: 10,
        hasFourthFollowup: false,
        fifthFollowUpText: null,
        fifthFollowUpDays: 20,
        hasFifthFollowup: false,
        sixthFollowUpText: null,
        sixthFollowUpDays: 30,
        hasSixthFollowup: false,
      });
      templateId = createdTemplate.templateId;
    } else {
      const template = {
        title: selectedTemplate.title,
        templateId: selectedTemplate.templateId,
        connectionRequestText: connectionRequest,
        firstFollowUpText,
        firstFollowUpDays,
        hasFirstFollowup:
          firstFollowUpText && firstFollowUpText.length > 0,
        secondFollowUpText,
        secondFollowUpDays,
        hasSecondFollowup:
          secondFollowUpText && secondFollowUpText.length > 0,
        thirdFollowUpText: null,
        thirdFollowUpDays: 6,
        hasThirdFollowup: false,
        fourthFollowUpText: null,
        fourthFollowUpDays: 10,
        hasFourthFollowup: false,
        fifthFollowUpText: null,
        fifthFollowUpDays: 20,
        hasFifthFollowup: false,
        sixthFollowUpText: null,
        sixthFollowUpDays: 30,
        hasSixthFollowup: false,
      };

      try {
        await saveTemplate(template);
      } catch (e) {}
    }

    // create campaign
    const createdCampaign = await createCampaign({
      title,
      dailyLimit: 12,
      contacts: contacts.length,
      accountId: account.accountId,
      profileId: profile.profileId,
    });

    const contactsToUpload = [];
    // create contacts
    for (let index in contacts) {
      const contact = contacts[index];
      contact['templateId'] = templateId;
      contact['campaignId'] = createdCampaign.campaignId;
      contact['accountId'] = createdCampaign.accountId;
      contact['campaignUsername'] = createdCampaign.username;
      contact['campaignSlug'] = createdCampaign.slug;
      contact['connectionRequestText'] = parseCopy(
        connectionRequest,
        index
      );
      contact['firstFollowUpText'] = parseCopy(
        firstFollowUpText,
        index
      );
      contact['secondFollowUpText'] = parseCopy(
        secondFollowUpText,
        index
      );
      contact['firstFollowUpDays'] = firstFollowUpDays;
      contact['secondFollowUpDays'] = secondFollowUpDays;
      contact['secondFollowUpDays'] = secondFollowUpDays;
      contact['hasFirstFollowup'] = true;
      contact['hasSecondFollowup'] = true;
      if (contact.firstName !== 'n/a') contactsToUpload.push(contact);
    }

    console.log(contactsToUpload);

    var i,
      j,
      coolContacts,
      chunk = 25;
    for (i = 0, j = contactsToUpload.length; i < j; i += chunk) {
      coolContacts = contactsToUpload.slice(i, i + chunk);
      await createContact({ contacts: coolContacts });
    }

    const contactsFromCampaign = await contactsInCamapign(
      createdCampaign.campaignId
    );

    if (contactsFromCampaign.length > 0) {
      const updatedCampaign = createdCampaign;
      updatedCampaign['contacts'] = contactsFromCampaign.length;
      await saveCampaign(updatedCampaign);

      toast({
        title: `${contactsFromCampaign.length} contacts have been imported!`,
        description:
          "If you turn on the campaign today we'll start sending messages on your behalf tomorrow.",
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } else {
      await deleteCampaign(createdCampaign);
      toast({
        title: `No new contacts found!`,
        description:
          "We don't let you upload the same contact twice.",
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
    }

    onClose();

    setScreen('Contacts');
  }

  return (
    <>
      {profile && !profile.paid && profile.campaignCount === 0 && (
        <Button
          iconSpacing="1"
          size="sm"
          leftIcon={<RiAddFill fontSize="1.25em" />}
          onClick={onOpen}
          colorScheme="blue"
        >
          New Campaign
        </Button>
      )}
      {profile && profile.paid && (
        <Button
          iconSpacing="1"
          colorScheme="blue"
          size="sm"
          leftIcon={<RiAddFill fontSize="1.25em" />}
          onClick={onOpen}
        >
          New Campaign
        </Button>
      )}
      <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent pb={4}>
          <ModalHeader fontWeight="bold">New Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mx="auto" maxW="2xl" minH="400px">
              <Steps activeStep={activeStep}>
                <Step title="Contacts">
                  <StepContent>
                    <Stack spacing="4">
                      <Accordion pt={0}>
                        {/* <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  Boolean Search{" "}
                                  <Badge
                                    variant="subtle"
                                    colorScheme="green"
                                    size="xs"
                                  >
                                    Recommended
                                  </Badge>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={6}>
                              You can use a Boolean Search to find 2nd degree
                              contacts that fit a specific search term. The
                              simpliest way to get started is to include a job
                              title in a specific location like "Marketer in New
                              York" or "Founder in Toronto".{" "}
                              <FormControl>
                                <Input
                                  autoFocus
                                  type="text"
                                  value={keywords}
                                  my={2}
                                  placeholder="Founder in Toronto"
                                  onChange={(e) => setKeywords(e.target.value)}
                                />
                              </FormControl>
                              <HStack>
                                <Button
                                  onClick={() => importSearch()}
                                  size="sm"
                                  isLoading={isLoading}
                                  mt={1}
                                  isDisabled={keywords.length < 3}
                                  colorScheme="blue"
                                >
                                  Import contacts
                                </Button>
                              </HStack>
                              <br />
                            </AccordionPanel>
                          </AccordionItem>{" "} */}
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                Lead List{' '}
                                <Badge
                                  variant="subtle"
                                  colorScheme="blue"
                                  size="xs"
                                >
                                  Sales Nav Required
                                </Badge>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            Importing contacts from a LinkedIn Lead
                            List is the best way to import contacts
                            into Conversify. Email david@conversify.ai
                            your sales nav url and LinkedIn email.
                            You'll get a CSV of contacts in return
                            that you can use to create your campaign
                            below.
                          </AccordionPanel>
                        </AccordionItem>
                        {/* <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  Saved Search{" "}
                                  <Badge
                                    variant="subtle"
                                    colorScheme="blue"
                                    size="xs"
                                  >
                                    Sales Nav Required
                                  </Badge>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              Import contacts from a LinkedIn Saved Search is the
                              fastest way to import contacts into Conversify. Copy
                              and paste a link to the Saved Search here to get
                              started.
                              <FormControl>
                                <Input
                                  autoFocus
                                  type="text"
                                  value={savedSearchUrl}
                                  my={2}
                                  placeholder="https://www.linkedin.com/sales/search/people?savedSearchId=23355660"
                                  onChange={(e) =>
                                    setSavedSearchUrl(e.target.value)
                                  }
                                />
                              </FormControl>
                              <HStack>
                                <Button
                                  onClick={() => importSavedSearch()}
                                  size="sm"
                                  isLoading={isLoading}
                                  colorScheme="blue"
                                  isDisabled={
                                    !savedSearchUrl.includes(
                                      "/sales/search/people"
                                    )
                                  }
                                >
                                  Import contacts
                                </Button>
                              </HStack>
                            </AccordionPanel>
                          </AccordionItem> */}
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                CSV Upload
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text mb={2}>
                              Upload a CSV of contacts. You can only
                              upload 250 contacts at a time. Columns
                              headers can include firstName, lastName,
                              company, email, phone, title, and{' '}
                              <b>
                                must include linkedinUrl and
                                firstName.
                              </b>
                            </Text>
                            <FormControl>
                              <FormLabel>Name</FormLabel>
                              <Input
                                type="text"
                                value={title}
                                placeholder="Founders in Toronto"
                                onChange={(e) =>
                                  setTitle(e.target.value)
                                }
                              />
                              <FormHelperText>
                                What will you call this set of
                                contacts?
                              </FormHelperText>
                            </FormControl>
                            {title.length > 2 && (
                              <FormControl mt={4}>
                                <FormLabel>CSV of contacts</FormLabel>
                                <CSVReader
                                  disabled={true}
                                  onFileLoaded={handleForce}
                                />
                              </FormControl>
                            )}
                            {headingsError && (
                              <Alert mt={2} status="error">
                                <AlertIcon />
                                <AlertDescription mr={2}>
                                  You column headers must include
                                  linkedinUrl and firstName.
                                </AlertDescription>
                              </Alert>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Stack>
                  </StepContent>
                </Step>
                <Step title="Campaign">
                  <StepContent>
                    <Text mb={2}>
                      It'll take a few minutes to pull data from your
                      LinkedIn account. In the meantime, tell us what
                      you'd like to say and we can send those messages
                      on your behalf.{' '}
                      <b>
                        You can update these messages at any time.
                      </b>
                    </Text>
                    {templates.length > 0 && (
                      <Select
                        minW={{ md: '100px' }}
                        rounded="base"
                        size="sm"
                        placeholder={selectedTemplate.title}
                        onChange={(e) =>
                          updateTemplate(e.target.value)
                        }
                      >
                        {templates &&
                          templates.map((template, i) => (
                            <option key={i}>{template.title}</option>
                          ))}
                      </Select>
                    )}
                    <Stack my={4} spacing="4">
                      <FormControl id="role">
                        <HStack
                          position={{ sm: 'absolute' }}
                          top={{ sm: '0' }}
                          insetEnd={{ sm: '0' }}
                          mt={{ base: '4', sm: '0' }}
                        >
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  size="xs"
                                  isActive={isOpen}
                                  as={Button}
                                  rightIcon={<HiChevronDown />}
                                >
                                  personalize
                                </MenuButton>
                                <MenuList>
                                  {personalizationVariables.map(
                                    (variable, i) => (
                                      <MenuItem
                                        onClick={() =>
                                          setConnectionRequest(
                                            connectionRequest.slice(
                                              0,
                                              requestTextIndex
                                            ) +
                                              variable +
                                              connectionRequest.slice(
                                                requestTextIndex
                                              )
                                          )
                                        }
                                        key={i}
                                      >
                                        {variable}
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </>
                            )}
                          </Menu>
                          <IconButton
                            aria-label="Edit"
                            icon={<HiChevronLeft />}
                            rounded="full"
                            size="xs"
                            onClick={() =>
                              showPreviousRequestNoteTemplate()
                            }
                          />
                          <IconButton
                            aria-label="Delete"
                            icon={<HiChevronRight />}
                            rounded="full"
                            size="xs"
                            onClick={() =>
                              showNextRequestNoteTemplate()
                            }
                          />
                        </HStack>
                        <FormLabel>Connection request</FormLabel>
                        <Textarea
                          value={connectionRequest}
                          rows={3}
                          size="xs"
                          onChange={(e) => {
                            setRequestTextIndex(
                              e.target.selectionStart
                            );
                            setConnectionRequest(e.target.value);
                          }}
                          onBlur={(e) =>
                            setRequestTextIndex(
                              e.target.selectionStart
                            )
                          }
                          placeholder="Leave this blank if you want to exclude a note."
                        />
                        {connectionRequest &&
                          connectionRequest.length > 300 && (
                            <Alert mt={2} status="error">
                              <AlertIcon />
                              <AlertDescription mr={2}>
                                Connection request must be less than
                                300 characters
                              </AlertDescription>
                            </Alert>
                          )}
                      </FormControl>
                      <FormControl>
                        <FormLabel>
                          How many days should we wait until we send
                          the first follow-up?
                        </FormLabel>
                        <Menu>
                          {({ isOpen }) => (
                            <>
                              <MenuButton
                                size="xs"
                                isActive={isOpen}
                                as={Button}
                                rightIcon={<HiChevronDown />}
                              >
                                {firstFollowUpDays}
                              </MenuButton>
                              <MenuList>
                                {[1, 2, 3, 5, 6, 7].map((day, i) => (
                                  <MenuItem
                                    key={i}
                                    onClick={() =>
                                      setFirstFollowUpDays(day)
                                    }
                                  >
                                    {day}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </>
                          )}
                        </Menu>
                      </FormControl>
                      <FormControl>
                        <HStack
                          position={{ sm: 'absolute' }}
                          top={{ sm: '0' }}
                          insetEnd={{ sm: '0' }}
                          mt={{ base: '4', sm: '0' }}
                        >
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  size="xs"
                                  isActive={isOpen}
                                  as={Button}
                                  rightIcon={<HiChevronDown />}
                                >
                                  personalize
                                </MenuButton>
                                <MenuList>
                                  {personalizationVariables.map(
                                    (variable, i) => (
                                      <MenuItem
                                        onClick={() =>
                                          setFirstFollowUpText(
                                            firstFollowUpText.slice(
                                              0,
                                              firstFollowupTextIndex
                                            ) +
                                              variable +
                                              firstFollowUpText.slice(
                                                firstFollowupTextIndex
                                              )
                                          )
                                        }
                                        key={i}
                                      >
                                        {variable}
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </>
                            )}
                          </Menu>
                        </HStack>
                        <FormLabel size="xs">
                          First follow-up message
                        </FormLabel>
                        <Textarea
                          value={firstFollowUpText}
                          rows={6}
                          onChange={(e) => {
                            setFirstFollowUpText(e.target.value);
                            setFirstFollowupTextIndex(
                              e.target.selectionStart
                            );
                          }}
                          onBlur={(e) =>
                            setFirstFollowupTextIndex(
                              e.target.selectionStart
                            )
                          }
                          size="xs"
                          placeholder="Leave this blank and we won't send a follow-up message."
                        />
                        <FormHelperText>
                          {`We'll send this if someone connects with you and doesn't reply.`}
                        </FormHelperText>
                        {firstFollowUpText.length > 500 && (
                          <Alert mt={4} status="error">
                            <AlertIcon />
                            <AlertDescription mr={2}>
                              Follow-up must be less than 500
                              characters
                            </AlertDescription>
                          </Alert>
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel>
                          How many days should we wait until we send
                          the second follow-up?
                        </FormLabel>
                        <Menu>
                          {({ isOpen }) => (
                            <>
                              <MenuButton
                                size="xs"
                                isActive={isOpen}
                                as={Button}
                                rightIcon={<HiChevronDown />}
                              >
                                {secondFollowUpDays}
                              </MenuButton>
                              <MenuList>
                                {[1, 2, 3, 5, 6, 7].map((day, i) => (
                                  <MenuItem
                                    key={i}
                                    onClick={() =>
                                      setSecondFollowUpDays(day)
                                    }
                                  >
                                    {day}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </>
                          )}
                        </Menu>
                      </FormControl>
                      <FormControl>
                        <HStack
                          position={{ sm: 'absolute' }}
                          top={{ sm: '0' }}
                          insetEnd={{ sm: '0' }}
                          mt={{ base: '4', sm: '0' }}
                        >
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  size="xs"
                                  isActive={isOpen}
                                  as={Button}
                                  rightIcon={<HiChevronDown />}
                                >
                                  personalize
                                </MenuButton>
                                <MenuList>
                                  {personalizationVariables.map(
                                    (variable, i) => (
                                      <MenuItem
                                        onClick={() =>
                                          setSecondFollowUpText(
                                            secondFollowUpText.slice(
                                              0,
                                              secondFollowupTextIndex
                                            ) +
                                              variable +
                                              secondFollowUpText.slice(
                                                secondFollowupTextIndex
                                              )
                                          )
                                        }
                                        key={i}
                                      >
                                        {variable}
                                      </MenuItem>
                                    )
                                  )}
                                </MenuList>
                              </>
                            )}
                          </Menu>
                        </HStack>
                        <FormLabel size="xs">
                          Second follow-up message
                        </FormLabel>
                        <Textarea
                          value={secondFollowUpText}
                          rows={6}
                          onChange={(e) => {
                            setSecondFollowUpText(e.target.value);
                            setSecondFollowupTextIndex(
                              e.target.selectionStart
                            );
                          }}
                          onBlur={(e) =>
                            setSecondFollowupTextIndex(
                              e.target.selectionStart
                            )
                          }
                          size="xs"
                          placeholder="Leave this blank and we won't send a follow-up message."
                        />
                        {secondFollowUpText &&
                          secondFollowUpText.length > 500 && (
                            <Alert mt={4} status="error">
                              <AlertIcon />
                              <AlertDescription mr={2}>
                                Follow-up must be less than 500
                                characters
                              </AlertDescription>
                            </Alert>
                          )}
                      </FormControl>
                    </Stack>
                    <Stack>
                      <HStack>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleCreateCampaign()}
                          isLoading={isLoading}
                        >
                          Save contacts
                        </Button>
                      </HStack>
                    </Stack>
                  </StepContent>
                </Step>
              </Steps>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportContactsModal;
