import React from "react";
import {
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { FiLinkedin, FiMail, FiPhone } from "react-icons/fi";
import EditContactModal from "./EditContactModal";

const ContactsTable = ({
  contacts,
  selectContact,
  selectedContacts,
  selectAllContacts,
  selectAll,
  viewContact,
  showConversation,
  copyPhone,
  copyEmail,
}) => {
  return (
    <Table
      mt={4}
      mb={0}
      fontSize="xs"
      borderWidth="1px"
      width="100%"
      variant="simple"
    >
      <Thead bg={mode("gray.50", "gray.800")}>
        <Tr>
          <Th pr={0} whiteSpace="nowrap">
            <input
              type="checkbox"
              checked={selectAllContacts}
              onChange={() => selectAll()}
            />
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            First Name
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Last Name
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Status
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Email
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Phone
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {contacts.map((contact, index) => (
          <CampaignsRow
            key={index}
            contact={contact}
            selectContact={selectContact}
            selectedContacts={selectedContacts}
            viewContact={viewContact}
            showConversation={showConversation}
            copyEmail={copyEmail}
            copyPhone={copyPhone}
          />
        ))}
      </Tbody>
    </Table>
  );
};

const CampaignsRow = ({
  contact,
  selectContact,
  selectedContacts,
  showConversation,
  copyPhone,
  copyEmail,
}) => {
  return (
    <Tr>
      <Td pr={0} whiteSpace="nowrap">
        <HStack>
          <input
            type="checkbox"
            checked={selectedContacts.includes(contact.contactId)}
            onChange={() => selectContact(contact.contactId)}
          />
          <IconButton
            icon={<FiLinkedin />}
            as="a"
            size="xs"
            alt="link to linkedin profile"
            target="_blank"
            rel="noopener noreferrer"
            href={contact.conversationUrl || contact.linkedinUrl}
          />
          <EditContactModal contact={contact} />
        </HStack>
      </Td>
      <Td whiteSpace="nowrap" fontWeight="bold">
        <HStack>
          <Text>{contact.firstName && contact.firstName.slice(0, 10)}</Text>
        </HStack>{" "}
      </Td>
      <Td whiteSpace="nowrap">
        {contact.lastName && contact.lastName.slice(0, 10)}
      </Td>
      <Td whiteSpace="nowrap">
        <HStack>
          <Text>
            {contact.hasReplied
              ? "Replied"
              : contact.isConnection
              ? "Connected"
              : contact.connectionRequestSent
              ? "Invited"
              : "New"}
          </Text>
        </HStack>
      </Td>
      <Td whiteSpace="nowrap">
        {" "}
        {contact.email && (
          <IconButton
            aria-label="Delete"
            icon={<FiMail />}
            rounded="full"
            size="xs"
            mr={1}
            onClick={() => copyEmail(contact.email)}
          />
        )}
      </Td>
      <Td whiteSpace="nowrap">
        {" "}
        {contact.phone && (
          <IconButton
            aria-label="Delete"
            icon={<FiPhone />}
            rounded="full"
            size="xs"
            mr={1}
            onClick={() => copyPhone(contact.phone)}
          />
        )}
      </Td>
    </Tr>
  );
};

export default ContactsTable;
