import React, { useState, useEffect } from "react";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode,
  HStack,
  Button,
  Text,
} from "@chakra-ui/react";
import UpdateStatus from "./UpdateStatus";
import { FiTrash } from "react-icons/fi";
import ViewCampaignModal from "./ViewCampaignModal";
import EditCampaignModal from "./EditCampaignModal";
import UpdateSendingLimit from "./UpdateSendingLimit";
import { API, Auth } from "aws-amplify";

const CampaignsTable = ({ campaigns, setCampaigns }) => {
  return (
    <Table mt="0" mb={0} borderWidth="1px" fontSize="xs" width="100%">
      <Thead bg={mode("gray.50", "gray.800")}>
        <Tr>
          <Th whiteSpace="nowrap" scope="col">
            Campaign
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Daily
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Contacts
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Requests
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Connections
          </Th>
          <Th whiteSpace="nowrap" scope="col">
            Replies
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {campaigns
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((campaign, index) => (
            <CampaignsRow
              key={index}
              campaign={campaign}
              setCampaigns={setCampaigns}
            />
          ))}
      </Tbody>
    </Table>
  );
};

const CampaignsRow = ({ campaign, setCampaigns }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        setProfile(profile);
      } catch (e) {}
    }

    onLoad();
  }, []);

  async function deleteCampaign(campaign) {
    return API.del("campaigns", `/campaigns/${campaign.campaignId}`);
  }

  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  async function handleDelete(campaign) {
    const confirmed = window.confirm(
      "Are you sure you want to delete this campaign?"
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);

    try {
      await deleteCampaign(campaign);
      const campaigns = await loadCampaigns();
      setCampaigns(
        campaigns.filter((camp) => camp.accountId === campaign.accountId)
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <Tr>
      <Td whiteSpace="nowrap" fontWeight="bold">
        <HStack>
          <UpdateStatus campaign={campaign} />
          <ViewCampaignModal campaignId={campaign.campaignId} />
          <EditCampaignModal campaignId={campaign.campaignId} />
          {profile && profile.paid && (
            <Button
              isLoading={isLoading}
              onClick={() => handleDelete(campaign)}
              size="xs"
            >
              <FiTrash />
            </Button>
          )}

          <Text>{campaign.title}</Text>
        </HStack>
      </Td>
      <Td whiteSpace="nowrap">
        <UpdateSendingLimit campaign={campaign} />
      </Td>
      <Td whiteSpace="nowrap">{campaign.contacts}</Td>
      <Td whiteSpace="nowrap">{campaign.requests}</Td>
      <Td whiteSpace="nowrap">{campaign.connections}</Td>
      <Td whiteSpace="nowrap">{campaign.conversations}</Td>
    </Tr>
  );
};

export default CampaignsTable;
