import {
  Box,
  Button,
  HStack,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
// import UpdateCloseKey from "./UpdateCloseKey";

const FieldGroup = (props) => {
  const { title, description, ...boxProps } = props;
  return (
    <Box>
      <Text fontWeight="semibold">{title}</Text>
      {description && (
        <Text color={mode("gray.600", "gray.400")} fontSize="sm">
          {description}
        </Text>
      )}
      <Box pt="5" {...boxProps} />
    </Box>
  );
};

const LinkedInSettings = ({ account, handleDelete }) => (
  <Stack as="section" p={6} spacing="6">
    <Stack divider={<StackDivider />} spacing="6">
      {/* <FieldGroup
        title="Integrate Close CRM"
        description="Sync data between Close CRM and your LinkedIn account."
      >
        <HStack mt="0">
          <UpdateCloseKey account={account} />
        </HStack>
      </FieldGroup> */}
      <FieldGroup
        title="LinkedIn password"
        description="Update the LinkedIn password you've saved with Conversify."
      >
        <HStack mt="0">
          <Button
            as="a"
            href={`/accounts/${account.accountId}/edit`}
            size="sm"
            fontWeight="normal"
          >
            Update password
          </Button>
        </HStack>
      </FieldGroup>

      <FieldGroup
        title="Delete account and data"
        description="Once you delete your account, there is no going back."
      >
        <HStack mt="0">
          <Button
            onClick={(e) => handleDelete(e)}
            size="sm"
            colorScheme="red"
            fontWeight="normal"
          >
            Delete account
          </Button>
        </HStack>
      </FieldGroup>
    </Stack>
  </Stack>
);

export default LinkedInSettings;
