import React, { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { HiChevronDown } from "react-icons/hi";
import { API } from "aws-amplify";

const UpdateSendingLimit = ({ campaign }) => {
  const [sendingLimit, setSendingLimit] = useState(campaign.dailyLimit);

  async function toggleSendingLimit(limit) {
    setSendingLimit(limit);
    const updatedCampaign = campaign;
    updatedCampaign["dailyLimit"] = limit;

    try {
      await saveCampaign(updatedCampaign);
    } catch (e) {}
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${campaign.campaignId}`, {
      body: campaign,
    });
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            size="sm"
            isActive={isOpen}
            as={Button}
            rightIcon={<HiChevronDown />}
          >
            {sendingLimit}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => toggleSendingLimit(0)}>0</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(2)}>2</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(4)}>4</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(8)}>8</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(10)}>10</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(12)}>12</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(13)}>13</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(15)}>15</MenuItem>
            <MenuItem onClick={() => toggleSendingLimit(18)}>18</MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UpdateSendingLimit;
