import {
  Box,
  Text,
  useColorModeValue,
  Stack,
  Heading,
  Link,
  useColorModeValue as mode,
  HStack,
  Icon,
  Flex,
  Button,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import {} from "react-icons/fi";
import { FiBookOpen, FiLinkedin, FiPlus, FiArrowLeft } from "react-icons/fi";
import AccountSettings from "../components/AccountSettings";
import SubscribeBanner from "./SubscribeBanner";

const ColumnIconButton = (props) => (
  <IconButton
    size="sm"
    fontSize="md"
    variant="ghost"
    _hover={{
      bg: useColorModeValue("gray.100", "gray.700"),
    }}
    _active={{
      bg: useColorModeValue("gray.200", "gray.600"),
    }}
    _focus={{
      boxShadow: "none",
    }}
    _focusVisible={{
      boxShadow: "outline",
    }}
    {...props}
  />
);

const ColumnHeader = (props) => (
  <Flex
    minH="12"
    position="sticky"
    zIndex={1}
    top="0"
    px="3"
    align="center"
    bg={useColorModeValue("white", "gray.800")}
    color={useColorModeValue("gray.700", "white")}
    {...props}
  />
);
const ColumnHeading = (props) => (
  <Heading fontWeight="bold" fontSize="sm" lineHeight="1.25rem" {...props} />
);

const NavLink = (props) => {
  const { url, icon, ...linkProps } = props;
  return (
    <Link
      as="a"
      px="2"
      py="1.5"
      borderRadius="md"
      _hover={{
        bg: mode("gray.100", "gray.700"),
      }}
      _activeLink={{
        bg: "gray.700",
        color: "white",
      }}
      href={url}
      {...linkProps}
    >
      <HStack justify="space-between">
        <HStack as="a" spacing="3">
          <Icon as={icon} />
          <Text as="span" fontSize="sm" lineHeight="1.25rem">
            {props.children}
          </Text>
        </HStack>
      </HStack>
    </Link>
  );
};
const NavHeading = (props) => (
  <Text
    as="h4"
    fontSize="xs"
    fontWeight="semibold"
    px="2"
    lineHeight="1.25"
    color={mode("gray.600", "gray.400")}
    {...props}
  />
);

export default function Templates(props) {
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [accounts, setAccounts] = useState(false);
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadAccounts() {
      return API.get("accounts", "/accounts");
    }

    async function onLoad() {
      try {
        const templates = await loadTemplates();
        const accounts = await loadAccounts();
        setAccounts(accounts);
        setTemplates(templates);
        setTemplate(templates[0]);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function deleteTemplate() {
    return API.del("templates", `/templates/${template.templateId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this template?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleteLoading(true);

    try {
      await deleteTemplate();
      const updatedTemplates = templates.filter(
        (temp) => temp.templateId !== template.templateId
      );
      setTemplates(updatedTemplates);
      setTemplate(updatedTemplates[0]);
      setIsDeleteLoading(false);
    } catch (e) {
      setIsDeleteLoading(false);
      onError(e);
    }
  }

  function loadTemplates() {
    return API.get("templates", "/templates");
  }

  return (
    <>
      <SubscribeBanner />
      <Flex h={"100vh"}>
        <Box
          height="full"
          width={{
            md: "14rem",
            xl: "18rem",
          }}
          display={{
            base: "none",
            lg: "initial",
          }}
          overflowY="auto"
          borderRightWidth="1px"
        >
          <Flex
            as="nav"
            height="full"
            direction="column"
            justify="space-between"
            {...props}
          >
            <Stack spacing="3">
              <Stack px="4" mt="3" spacing="6">
                <Stack spacing="3">
                  <AccountSettings props={props} />
                  <NavHeading>LinkedIn Accounts</NavHeading>
                  <Stack spacing="1">
                    <NavLink icon={FiPlus} url={`/accounts/new`}>
                      New Account
                    </NavLink>
                    {accounts &&
                      accounts.map((account, i) => (
                        <NavLink
                          key={i}
                          icon={FiLinkedin}
                          url={`/accounts/${account.accountId}`}
                          aria-current={
                            props.match.params.id === account.accountId &&
                            "page"
                          }
                        >
                          {account.firstName} {account.lastName}
                        </NavLink>
                      ))}
                  </Stack>
                </Stack>

                <Stack spacing="3">
                  <NavHeading>Templates</NavHeading>
                  <Stack spacing="1">
                    <NavLink icon={FiPlus} url={`/templates/new`}>
                      New Template
                    </NavLink>
                    <NavLink
                      url="/templates"
                      icon={FiBookOpen}
                      aria-current="page"
                    >
                      Template Library
                    </NavLink>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {
              //   <Box borderTopWidth="1px">
              //   <NavButton>Settings</NavButton>
              // </Box>
            }
          </Flex>
        </Box>
        <Box
          borderRightWidth="1px"
          width={{
            md: "14rem",
            xl: "18rem",
          }}
          display={{
            base: "none",
            md: "initial",
          }}
          overflowY="auto"
          onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
        >
          <ColumnHeader shadow={sidebarIsScrolled ? "base" : "none"}>
            <HStack justify="space-between" width="full">
              <ColumnHeading>
                <HStack as="a" spacing="3">
                  <Icon as={FiBookOpen} />
                  <Text as="span" fontSize="md" lineHeight="1.25rem">
                    Template Library
                  </Text>
                </HStack>
              </ColumnHeading>
            </HStack>
          </ColumnHeader>
          {isLoading ? (
            <Stack
              spacing={{
                base: "1px",
                lg: "1",
              }}
              px={{
                lg: "3",
              }}
              py="3"
              {...props}
            >
              <Link
                aria-current={"page"}
                _hover={{
                  textDecoration: "none",
                  bg: mode("gray.100", "gray.700"),
                }}
                _activeLink={{
                  bg: "gray.700",
                  color: "white",
                }}
                borderRadius={{
                  lg: "lg",
                }}
              >
                <Stack
                  spacing="1"
                  py={{
                    base: "3",
                    lg: "2",
                  }}
                  px={{
                    base: "3.5",
                    lg: "3",
                  }}
                  fontSize="sm"
                  lineHeight="1.25rem"
                >
                  <Text fontWeight="medium">
                    <Skeleton height="15px" w="full" />
                  </Text>
                </Stack>
              </Link>
            </Stack>
          ) : (
            <Stack
              spacing={{
                base: "1px",
                lg: "1",
              }}
              px={{
                lg: "3",
              }}
              py="3"
              {...props}
            >
              {templates && templates.length === 0 ? (
                <Link
                  aria-current={"page"}
                  _hover={{
                    textDecoration: "none",
                    bg: mode("gray.100", "gray.700"),
                  }}
                  _activeLink={{
                    bg: "gray.700",
                    color: "white",
                  }}
                  borderRadius={{
                    lg: "lg",
                  }}
                >
                  <Stack
                    spacing="1"
                    py={{
                      base: "3",
                      lg: "2",
                    }}
                    px={{
                      base: "3.5",
                      lg: "3",
                    }}
                    fontSize="sm"
                    lineHeight="1.25rem"
                  >
                    <Text fontWeight="medium">No templates yet</Text>
                  </Stack>
                </Link>
              ) : (
                templates.map((temp, i) => (
                  <Link
                    key={i}
                    aria-current={
                      template && template.title === temp.title
                        ? "page"
                        : undefined
                    }
                    _hover={{
                      textDecoration: "none",
                      bg: mode("gray.100", "gray.700"),
                    }}
                    _activeLink={{
                      bg: "gray.700",
                      color: "white",
                    }}
                    borderRadius={{
                      lg: "lg",
                    }}
                    onClick={() =>
                      setTemplate(
                        templates.filter(
                          (filteredTemp) =>
                            filteredTemp.templateId === temp.templateId
                        )[0]
                      )
                    }
                  >
                    <Stack
                      spacing="1"
                      py={{
                        base: "3",
                        lg: "2",
                      }}
                      px={{
                        base: "3.5",
                        lg: "3",
                      }}
                      fontSize="sm"
                      lineHeight="1.25rem"
                    >
                      <Text fontWeight="medium">{temp.title}</Text>
                    </Stack>
                  </Link>
                ))
              )}
            </Stack>
          )}
        </Box>
        <Box flex="1" overflowY="auto">
          <Box as="main">
            <Stack spacing="8">
              <Stack spacing="3">
                <ColumnHeader shadow={"base"}>
                  <HStack justify="space-between" width="full">
                    <HStack spacing="3">
                      <ColumnIconButton
                        aria-label="Navigate back"
                        icon={<FiArrowLeft />}
                        display={{ base: "inline-flex", md: "none" }}
                      />
                      <ColumnHeading>
                        {isLoading ? (
                          <Skeleton height="20px" w="245px" />
                        ) : templates.length === 0 ? (
                          "No templates yet"
                        ) : (
                          template && template.title
                        )}
                      </ColumnHeading>
                    </HStack>
                  </HStack>
                </ColumnHeader>
              </Stack>
              {isLoading ? (
                <Stack px={6} spacing="3">
                  <Skeleton height={"20px"} width={"400px"} />
                </Stack>
              ) : templates.length === 0 ? (
                <Stack px={6} spacing="3">
                  <Text>
                    LinkedIn templates can make your life much easier, allowing
                    you to reach more prospects in less time and close more
                    deals. We encourage you to create multiple templates so you
                    test which one is better in the campaigns you create. Each
                    template is made of one connection request message and up to
                    2 follow-up messages. We will only send follow-up messages
                    if someone connects with you and doesn't reply. If the
                    person replies, it's on you to continue the conversation.
                  </Text>
                  <Link
                    as="a"
                    href="/templates/new"
                    color={mode("blue.600", "blue.200")}
                    display={{
                      base: "block",
                      md: "inline-block",
                    }}
                  >
                    Create your first template
                  </Link>
                </Stack>
              ) : (
                <Stack px={6} spacing="8">
                  {template && (
                    <Table
                      fontSize="sm"
                      borderWidth="1px"
                      width="100%"
                      variant="simple"
                    >
                      <Thead bg={mode("gray.50", "gray.800")}>
                        <Tr>
                          <Th whiteSpace="nowrap" scope="col">
                            Contacts
                          </Th>
                          <Th whiteSpace="nowrap" scope="col">
                            Requests
                          </Th>
                          <Th whiteSpace="nowrap" scope="col">
                            Connections
                          </Th>
                          <Th whiteSpace="nowrap" scope="col">
                            Replies
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td whiteSpace="nowrap" fontWeight="bold">
                            {template.contacts}
                          </Td>
                          <Td whiteSpace="nowrap" fontWeight="bold">
                            {template.requests}
                          </Td>
                          <Td whiteSpace="nowrap" fontWeight="bold">
                            {template.connections}
                          </Td>
                          <Td whiteSpace="nowrap" fontWeight="bold">
                            {template.conversations}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  )}
                  <HStack>
                    {template && (
                      <Button
                        variant="outline"
                        as="a"
                        size="sm"
                        href={`/templates/${template.templateId}/edit`}
                      >
                        Edit template
                      </Button>
                    )}
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={(e) => handleDelete(e)}
                      isDeleteLoading={isDeleteLoading}
                    >
                      Delete template
                    </Button>{" "}
                  </HStack>
                  <Stack spacing="2">
                    <Text fontWeight={"bold"}>Connection Request</Text>
                    <Text>{template && template.connectionRequestText}</Text>
                  </Stack>
                  {template &&
                    template.firstFollowUpText &&
                    template &&
                    template.firstFollowUpText.length > 0 && (
                      <Text color={"#4399e1"}>
                        Wait {template && template.firstFollowUpDays}{" "}
                        {template && template.firstFollowUpDays === 1
                          ? "day"
                          : "days"}
                      </Text>
                    )}

                  {template &&
                    template.firstFollowUpText &&
                    template &&
                    template.firstFollowUpText.length > 0 && (
                      <Stack spacing="2">
                        <Text fontWeight={"bold"}>First follow-up</Text>
                        <Text>{template && template.firstFollowUpText}</Text>
                      </Stack>
                    )}
                  {template &&
                    template.secondFollowUpText &&
                    template &&
                    template.secondFollowUpText.length > 0 && (
                      <Text color={"#4399e1"}>
                        Wait {template && template.secondFollowUpDays}{" "}
                        {template && template.secondFollowUpDays === 1
                          ? "day"
                          : "days"}
                      </Text>
                    )}
                  {template &&
                    template.secondFollowUpText &&
                    template &&
                    template.secondFollowUpText.length > 0 && (
                      <Stack spacing="2">
                        <Text fontWeight={"bold"}>Second follow-up</Text>
                        <Text>{template && template.secondFollowUpText}</Text>
                      </Stack>
                    )}
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
