import { CardElement, injectStripe } from "react-stripe-elements";
import {
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Flex,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useState } from "react";

const BillingForm = (props) => {
  const [name, setName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [accounts, setAccounts] = useState(props.numOfAccounts);

  function validateForm() {
    return name !== "" && isCardComplete && accounts > 0;
  }

  function handleCardFieldChange(event) {
    setIsCardComplete(event.complete);
  }

  async function handleSubmitClick(event) {
    event.preventDefault();

    setIsProcessing(true);

    const { token, error } = await props.stripe.createToken({ name });

    props.onSubmit({ accounts, token, error });
  }

  return (
    <chakra.form onSubmit={handleSubmitClick}>
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Number of LinkedIn accounts</FormLabel>
          <Input
            type="number"
            value={accounts}
            onChange={(e) => setAccounts(e.target.value)}
          />
          <FormHelperText>
            You'll be charged ${accounts * 59} USD per month for {accounts}{" "}
            {accounts === 1 ? "account" : "accounts"}.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <Flex align="baseline" justify="space-between">
            <FormLabel mb={1}>Name on card</FormLabel>
          </Flex>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <CardElement
          className="card-field"
          onChange={handleCardFieldChange}
          style={{
            base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif' },
          }}
        />
        <Button
          block
          bsSize="large"
          type="submit"
          colorScheme={"blue"}
          isLoading={isProcessing}
          disabled={!validateForm()}
        >
          Purchase
        </Button>
      </Stack>
    </chakra.form>
  );
};

export default injectStripe(BillingForm);
