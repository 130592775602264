import React from "react";
import { Box, Skeleton, Text } from "@chakra-ui/react";

const Th = (props) => (
  <Text
    as="th"
    textTransform="uppercase"
    fontSize="xs"
    color="gray.500"
    fontWeight="medium"
    px={4}
    {...props}
  />
);

const Td = (props) => (
  <Box
    as="td"
    color="gray.900"
    p={4}
    borderBottom="1px solid"
    borderBottomColor="gray.100"
    {...props}
  />
);

const Tr = (props) => (
  <Box
    as="tr"
    backgroundColor="gray.50"
    borderTopLeftRadius={8}
    borderTopRightRadius={8}
    borderBottom="1px solid"
    borderBottomColor="gray.200"
    height="40px"
    {...props}
  />
);

const Table = (props) => {
  return (
    <Box
      as="table"
      textAlign="left"
      backgroundColor="white"
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      {...props}
    />
  );
};

const SkeletonRow = ({ width }) => (
  <Box as="tr">
    <Td>
      <Skeleton height="15px" w="15px" my={4} />
    </Td>
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
    <Td>
      <Skeleton height="10px" w={width} my={4} />
    </Td>
  </Box>
);

const ContactsSkeleton = () => {
  return (
    <Box>
      <Skeleton height="25px" w="full" my={4} mt={0} />
      <Table borderWidth="1px" fontSize="sm" width="100%">
        <thead>
          <Tr>
            <Th pr={0}>
              <Skeleton height="15px" w="15px" my={4} />
            </Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Email</Th>
          </Tr>
        </thead>
        <tbody>
          <SkeletonRow width="15px" />
          <SkeletonRow width="30px" />
          <SkeletonRow width="30px" />
          <SkeletonRow width="55px" />
          <SkeletonRow width="30px" />
          <SkeletonRow width="30px" />
          <SkeletonRow width="55px" />
        </tbody>
      </Table>
    </Box>
  );
};

export default ContactsSkeleton;
